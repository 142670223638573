import React, { Component } from 'react';
import {InputWrapper, StyledPwdInput, Label} from "../common";
import WithEye from "./WithEye";


export default class PwdInput extends Component {


state={
    passwordType: "password",
    pwdValid: false,
    password: ""
}


componentDidMount(){
    this.setState(
        {
            pwdValid: true,
            pwdLabel: ""
   
         });
 }

togglePassWordType=(e)=>{
    console.log("on rentre dans togglepassword")
    let passwordType = this.state.passwordType;     
    passwordType === "password" ? this.setState(
        {passwordType : "input"}) 
        : 
        this.setState(
            {passwordType : "password"})        
}


checkPwd(password){  
    return password.length > 5 ? true : false
}


handlePwdBlur=(event)=>{
    let response = false;
    this.setState({[event.target.name]: event.target.value});
    response = this.checkPwd(this.props.password); 
    this.setState({pwdValid : response})
        
    }
  
  /**
     * 
     * @param {string} passwordType 
     * @returns {boolean} true or false
     */
    isPwd=(passwordType)=>{
        return (passwordType === "password")
    }

  

    render() {
        const isPwd = this.isPwd(this.state.passwordType)
        const pwdValid = this.state.pwdValid
   
 
        return (
            <>
            <InputWrapper>

                <Label isvalid = {pwdValid}>
                    {pwdValid ? this.props.Label : "Mot de passe invalide : 6 caractères requis"}
                </Label>
           
                <StyledPwdInput 
                type={this.state.passwordType} 
                name={this.props.name} 
                value={this.props.password} 
                placeholder={this.props.placeholder}
                onChange={this.props.onChange}
                required={true}
                onBlur = {this.handlePwdBlur}
                
                />

                <WithEye 
                name="eye" 
                passwordtype={this.state.passwordType} 
                onClick={(e)=>this.togglePassWordType(e)} 
                ispwd={isPwd}/>
                </InputWrapper>
                </>
        )
    }
}

import React, { useCallback, useContext, useEffect, useState } from 'react';
import {StyledFormCards} from '../common';
import { BookingContext } from '../contexts/BookingContext';
import BookingCard from './BookingCard';
import {filterBookings, setCategoryString} from "../data/clientUtils";
import { UserContext } from '../contexts/UserContext';


function BookingList(props){
    console.log("props booking list : ", props);
    const bookingContext = useContext(BookingContext);
    const userContext = useContext(UserContext);
    const user = userContext.user.id === -1 ? props.user : userContext.user;   
    const [message, setMessage] = useState(""); 
    const [reloadedTs, setReloadTs] = useState(props.validTs);
    const [reloadedTrainingTs, setReloadedTrainingTs] = useState(props.validTsStage);
    const [reloadedTsToDisable, setReloadedTsToDisable] = useState(props.tsToDisable);
    const [bookings, setBookings] = useState(user.bookings);


   

    const reloadTsToDisable = useCallback(async()=>{
      const disableRes = await bookingContext.getTsToDisable();
      if(disableRes !== undefined){
        if(disableRes.message){
          setMessage(disableRes.message);
        }else{
          setReloadedTsToDisable(disableRes.tsToDisable);
          console.log("les ts to disable : ", disableRes.tsToDisable);
        }
      }else{
        console.log("rechargement des créneaux désactivés en cours")
      }
    }, [])

    const reloadTs = useCallback(async()=>{      
    const tsRes = await bookingContext.getValidTimeStampsWithTimes();
    if (tsRes !== undefined){
      console.log("réponse contexte : ", tsRes);
      if (tsRes.timeStamps){        
        setReloadTs(tsRes.timeStamps);
        
      }else{
        console.log("erreur : ", tsRes);
      }      
    }
  }, 
  []);
  const reloadTrainingTs = useCallback(async()=>{
    console.log("reload training ts");
    const tsRes = await bookingContext.getAllValidTimeStamps();
    if (tsRes !== undefined){
      if (tsRes.timeStamps){
        console.log("reload training from context, ", tsRes.timeStamps);
        setReloadedTrainingTs(tsRes.timeStamps);
      }else{
        console.log("erreur : ", tsRes);
      }      
    }
  }, 
  []);
  const reloadUser = useCallback(async()=>{
    console.log("dans reload user");
    if(user && user.id !== -1){
    const userMail = user.mail?user.mail:user.email;
    const tempUser = await userContext.reloadUserAndBookings(userMail);
    if (tempUser !== undefined){
      if(tempUser.user){        
        console.log("reloaded user : ", tempUser.user);
        setBookings(tempUser.user.bookings);
        console.log("reloaded bookings : ", tempUser.user.bookings) ;
        bookingContext.setReloadUser(false);
        userContext.setUserIsToReload(false);    

      }else{  
        console.log("errerur récupe : ", tempUser.message) ;
        bookingContext.setReloadUser(false);
        userContext.setUserIsToReload(false);
      }
    }else{
      setMessage("Chargement en cours");
    }
  }else{
    console.log("pas de user authentifié");
    bookingContext.setReloadUser(false);
    userContext.setUserIsToReload(false);
  }
   }, [])
 
  
  useEffect(()=>{
    const noEmptyBookings = bookings !== undefined && filterBookings(bookings) && filterBookings(bookings).length !== 0;
    if(noEmptyBookings){
    if (!props.validTs || props.validTs.length === 0 ){
        reloadTs();   
      }  
      if (!props.validTsStage || (props.validTsStage !== undefined && props.validTsStage.length === 0)){
        reloadTrainingTs();
      }
      if(!props.tsToDisable || (props.tsToDisable !== undefined && props.tsToDisable.length === 0)){
        reloadTsToDisable();
      }
      if(bookingContext.reloadUser || userContext.userIsToReload){
        reloadUser();
      }
    }
  },[reloadTrainingTs, reloadTs, reloadTsToDisable,reloadUser])
  if (!window.navigator.onLine){
    return "Oups, il semblerait que vous ayez perdu votre connexion internet";
}
if ( bookings === undefined ){        
    return "...Chargement";
}else{
  if(filterBookings(bookings) === null || (filterBookings(bookings) !== undefined && filterBookings(bookings).length === 0)){
    return "Aucune réservation à venir";
  }
  if (bookings !== undefined && bookings.length === 0){
    return "Aucune réservation enregistrée";
  }
}

    const getTimeStampsByCat = (categoryId, timeStamps) =>{       
        
    if (timeStamps !== undefined && timeStamps !== null && categoryId !== undefined){
        // si on a des créneaux, on ne retourne que ceux de la categorie en param      
          const finalTimeStamps = timeStamps.filter(ts=>
          ts.category.toString() === categoryId.toString());
          console.log("dans getTsByCat : " + finalTimeStamps);     
          return finalTimeStamps;       
    }
}
   
    
    return  (
        <>     
        <span>{message && message}</span>
        <StyledFormCards>
          {(bookings !== null && bookings !== undefined && bookings.length > 0 && filterBookings(bookings).length > 0) ?
        filterBookings(bookings).map((filteredBooking, index)=>
      
            <BookingCard 
            userMail = {user.email?user.email:user.mail}
            key={index}
            bookingId = {filteredBooking.id}   
            category = {setCategoryString(filteredBooking.category)}
            categoryid = {filteredBooking.category}
            isFullWeek = {filteredBooking.is_full_week && filteredBooking.is_full_week}
            chosen_time_stamp = {filteredBooking.chosen_time_stamp && filteredBooking.chosen_time_stamp}
            created_at = {filteredBooking.created_at}
            bookingstate = {filteredBooking.booking_state}            
            quantity = {filteredBooking.quantity}
            tsToDisable = {reloadedTsToDisable && getTimeStampsByCat(filteredBooking.category, reloadedTsToDisable)} // chosen timestamps to disable
            //daystodisable = {(filteredBooking.category !== "2" && props.validTs !== undefined) ? bookingContext.setListToDisable(getTimeStampsByCat(filteredBooking.category, props.validTs), filteredBooking.category) : (props.validTsStage !== undefined && getTimeStampsByCat(filteredBooking.category, props.validTsStage))}            
            items = {filteredBooking.category !== 2 ? (reloadedTs && getTimeStampsByCat(filteredBooking.category, reloadedTs)) :  reloadedTrainingTs}   // ICI pourquoi par getTimeStampsByCat(filteredBooking.category,reloadedTrainingTs)       
            /> 
            
            )
        :
        <span>Aucune réservation prévue prochainement</span>
       
        }</StyledFormCards>
            </>
            ) 
            
        }
        export default BookingList

import React, {useContext, useState} from 'react';
import Logo from "./Logo";
import medail from "../images/medail_vignes.png";

import {
    PoneyMenu, 
    Menu,
    HeaderImg
    } from '../common';
import {GiCrossFlare} from 'react-icons/gi';
import {FaAlignRight} from 'react-icons/fa';
import {UserContext, withUser} from "../contexts/UserContext";
import {Link, useNavigate} from 'react-router-dom';
import fixedBckg from "../images/fixedBckg.png";




function Header(props) {
  
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    

    const handlePoneyClick=(event)=>{       
        menuIsOpen === false ? setMenuIsOpen(true) : setMenuIsOpen(false)
        console.log(menuIsOpen);        
    }
    
 
    
    const logout = async() => {
        console.log("on rentre dans logout")
        const user = await userContext.logout();
        if (user !== undefined){
            console.log("logout click user not undefined ", user);     
            userContext.setIsAuth(3);
            userContext.setLogoutClick(true);
            // redirect to home
            const options = {state: {isAuth:3, user: user.destroyedUser}};          
            if(user.destroyedUser.status !== 99){
            navigate("/logout", options);
        }else{
            navigate("/adminlogout", options);
        }
        }
        setMenuIsOpen(false);       
        props.context.resetGos(); // on reset les redirections
     
    }

    return (
        <> 
        <div className="header-fixed">
    
         <div className={props.context.user.status === 99 ? "header-wrapper-admin":"header-wrapper"}>           
        
        <header className="header">
            <div className="left-container">
                <Logo user={props.context.user}/>                           
                <div className="vignes-wrapper"><img className="medail-vignes" src={medail} alt="Les vignes du Frontonnais"/></div>      
            </div>
            
            <div className="center-container">           
              
            </div>
            <div className="right-container">
            <HeaderImg className="headerimg" src={fixedBckg} alt="Prestations"/>
            </div>
        <div className="header-display"></div>
        </header>      
    </div>
    <Menu >
    
    <PoneyMenu onClick={(e)=>{setTimeout(setMenuIsOpen(false), 3000)}} menuisopen={menuIsOpen}>
    <li className="poney-li"><Link to ="/"><span className="poney-span">Se connecter</span></Link></li>
    <li className="poney-li">
        <a href="https://lesecuriesdulysse.fr">
            <span className="poney-span">Retour au site</span>
        </a>
    </li>
    <li className="poney-li">
       <span className="poney-span"><GiCrossFlare/></span>
       <span className="poney-span" onClick={logout}>Se déconnecter</span>
    </li>
    
   </PoneyMenu> 
   <button  type="button" className="nav-btn" onClick={handlePoneyClick}>
        <FaAlignRight className="nav-icon" ></FaAlignRight>
    </button>
    </Menu> 
    
    </div> 
</>
    )
}
export default withUser(Header)
import React, {useState, useContext, useEffect} from 'react'
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3';
import {UserContext} from "../contexts/UserContext";
import connectionStrings from "../data/connectionStrings";
import axios from 'axios';

const SERVER = connectionStrings.SERVER;
const STATUSOPTION = {        
    AccessControlAllowOrigin : "*",
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    SameSite : 'None',
    Secure : 'true'            
   };

export default function Captcha() {
    const [captchaApiKey, setCaptchaApiKey] = useState("");
    const [_isMounted, set_isMounted] = useState(false);
    const [compteur, setCompteur] = useState(0);    
    const userContext = useContext(UserContext);
    const [recaptcha, setRecaptcha] = useState(null);
    const [serverStatus, setServerStatus] = useState();
  
    
    useEffect(() => {
        set_isMounted(true);
        async function loadRecaptchaElements () {
            let apiKeyByCat = connectionStrings.CAPTCHA_CLIENT_KEY_PROD;
             try {
                const serverStatusRes = await getServerStatus();        
                 if (serverStatusRes !== undefined){
                    if (serverStatusRes.error ){
                         userContext.setCaptchaMessage(serverStatusRes.error);
                         console.log(" C'est l'erreur : " + serverStatusRes.error);
                    }else{
                        const serverStatus = serverStatusRes.serverStatus;
                        //console.log("typeof : " + serverStatus + " et statut : " + serverStatus);
                        setServerStatus(serverStatus);                        
                        if ( serverStatus === 0 ){                            
                            apiKeyByCat = connectionStrings.CAPTCHA_CLIENT_KEY_LOCAL;                     
                        } else if (serverStatus === 1){                            
                            apiKeyByCat = connectionStrings.CAPTCHA_CLIENT_KEY_DEV; 
                        } else { // 2 ou 3 => PROD                            
                            apiKeyByCat = connectionStrings.CAPTCHA_CLIENT_KEY_PROD;                
                        }                     
                     
                     setCaptchaApiKey(apiKeyByCat);
                     
                         try {
                            loadReCaptcha (apiKeyByCat, verifyCallBack());
                            //console.log("on load recaptcha"); 
                         } catch (error) {
                             console.log ("erreur loading captcha");
                         }
                    }
                   
             }else{
                 console.log ("Serveur status undefined");
                 
             }
             
             } catch (error) {
                     console.log("erreur retour statut")
             }
              
        }
         /**
         * appel captcha
         * */
        
        if (!window.onLoadCaptchaV3Callback && window.navigator.onLine){
            loadRecaptchaElements();
    }
        return () => {
            //cleanup
        }
    }, [_isMounted])

    /**
   * CAPTCHA v3 GOOGLE
   * @param {*} recaptchaToken 
   */
     const verifyCallBack = async (recaptchaToken) =>{
        // captcha final
        
       // une fois le token généré, on peut procéder à l'appel      
        const myToken = recaptchaToken;      
        
        if ( myToken !== undefined && myToken !== null ){
            try{
                const resp = _isMounted && await userContext.getCaptchaResponse(myToken);                        
                console.log("dans le try, la réponse captcha compo captcha");
                handleCaptchaRes(resp);
            
            }catch(error){             
                console.log("erreur captcha compo captcha=> " + error);            
                // erreur captcha, on renvoie                   
                console.log("en duplicate compo captcha, on doit arriver ici");
                if (compteur < 2){
                    // relance la validation
                    updateToken();
                    // relance la demande
                    try {
                        const resp = _isMounted && await userContext.getCaptchaResponse(myToken);
                        handleCaptchaRes(resp);                    
                        setCompteur(compteur + 1);
                    } catch (error) {
                        userContext.setCaptchaMessage ("Erreur lors de la vérification captcha");
                    }
                    
                }else{
                    console.log("erreur après redemande : " + error);
                    userContext.setCaptchaMessage ("Erreur lors de la vérification captcha");
                }                            
            }
        }else{       
            userContext.setCaptchaMessage("Vérification en cours... si ce message persiste, merci de réactualiser la page");
        }
    }

    const updateToken = () =>{
        // demande un nouveau token
        recaptcha.execute();
    }
    const handleCaptchaRes= async (resp)=>{        
        console.log("on rentre dans handleCaptchaRes compo captcha: ");
        if (resp !== undefined){
            userContext.setEndOfCaptchaQuery();           
             if (resp.error){
                console.log("erreur captcha compo captcha: " + resp.error);
                userContext.setCaptchaMessage("Erreur dans la communication avec le serveur, merci de vérifier votre connexion internet");
            }else{ // resp.isCaptchaOk (true/false)                
                userContext.setCaptchaRes (resp.isCaptchaOk);
                console.log("réponse captcha compo captcha: " + resp.isCaptchaOk);
                userContext.setCaptchaMessage ("");
                if (resp.isCaptchaOk === false){
                    userContext.setCaptchaMessage ("Mauvaise réponse captcha compo captcha");
                }               
            } 
        }else{
         console.log("undefined compo captcha: chargement en cours");
         userContext.setCaptchaMessage("Vérifiation en cours...");        
        }
        
    }
    async function getServerStatus(){
        
        const CancelToken = axios.CancelToken;  
        let cancel;
        
        try { 
            // envoi du nouvel utilisateur en db
            const resp = await axios.post(`${SERVER}/getserverstatus`                    
            ,STATUSOPTION,
            {cancelToken: new CancelToken(function executor(cancelParam) {
                // An executor function receives a cancel function as a parameter
                cancel = cancelParam;
            })}, {timeout: 3000}
            );
            if (resp !== undefined){                        
                         
                if (resp.data !== undefined)
                {                    
                        
                        cancel(); // désabonnement
                        return ({serverStatus : resp.data.serverStatus}); // 0 : local 1: dev 2/3 : prod
                }
            }else{
                cancel(); // désabonnement    
                return ({error: "...Chargement en cours"});
            }         
            
            /*GESTION DES ERREURS*/
        }catch(error){            
            return ({error: "Un problème de communication avec le serveur est survenu, merci de réessayer ultérieurement"});               
        }
        
        /**
        * FIN POST captcha
        */ 
    }

    return (
        
        <ReCaptcha
        ref = {ref => setRecaptcha(ref)}
        sitekey = {captchaApiKey}                           
        verifyCallback = {verifyCallBack}        
        />
        
    )
}


import React, {useState, useContext, useEffect} from 'react';
import Stripe from './Stripe';
import { BookingContext} from "../contexts/BookingContext";



export default function BabyContent(props) {
    const [items, setItems] = useState();
    const [_isMounted, set_isMounted] = useState(false);
    const [daysToDisable, setDaysToDisable] = useState([]);
    const [myError, setMyError] = useState("");
    
    const bookingContext = useContext(BookingContext);
 
    

    useEffect(() => {
      set_isMounted(true);
      async function getTimeStampsFromContext(){
        /**
       * LOAD TIMESTAMPS!!!!
       */
        const resp = _isMounted === true && await bookingContext.getFinalTimeStamps(0);
        if (resp !== undefined){ 
          console.log("resp not undefined dans babyContent : " , resp);
          if (resp.timeStamps !== undefined){ 
            setItems (resp.timeStamps);     
            setDaysToDisable(bookingContext.setListToDisable(resp.timeStamps, 0));
          }   
        }else{
        // undefined
        setMyError("...Chargement en cours");
        }        
      }
   
    
      getTimeStampsFromContext();


      return () => {
        <div>{myError}</div>
      }
    }, [_isMounted, myError, bookingContext])



    return (
        <>
        <div className="pricing">
        <h2 className="form-h1">Important </h2>
        <span className="pricing-span">Merci de prévoir un accompagnateur par poney pour cette activité</span>
        <p><span className="pricing-span">Chaussures fermées indispensables pour tous</span></p>
        </div>
        <div className="pricing">
        <h2 className="form-h1">Nos tarifs </h2>
        <span className="pricing-title"> 30 minutes :  </span>                   
        <span className="pricing-span"> 12 € par enfant </span>
        <span className="pricing-title"> De 30 minutes à 45 minutes :  </span>
        <span className="pricing-span"> 15 € par enfant </span>
        <span className="pricing-title"> De 45 minutes à 1 heure :  </span>
        <span className="pricing-span"> 18 € par enfant </span>                
        <h2 className="form-h1">Important </h2>
        <span className="pricing-span">
          Le montant des arhhes est de 10€ par cavalier. 
          Le reste de la prestation est réglé en fin de promenade et calculé en fonction de sa durée.
        </span>   
        </div>
        <Stripe isAuth={props.isAuth} user={props.user} step = {props.step} start = {props.start} eventisok = {true} presta = "0" items = {items} tsToDisable = {props.tsToDisable} daysToDisable={daysToDisable}/>
        </>
        )
    }
 
          

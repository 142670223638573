export default [
    {
      sys:{
        id: "0"
      },
      fields: {
        numday: "0",
        day: "dimanche",
        possiblehours: [],
        category: "0",
      }
    },
    {
      sys: {
        id: "1"
      },
      fields: {
        numday: "1",
        day : "lundi",        
        possiblehours: [        
        ],
        category: "0",
      }
    },
    {
      sys: {
        id: "2"
      },
      fields: {
        numday: "2",
        day : "mardi",        
        possiblehours: [          
        ],
        category: "0",
      }
    },
    {
      sys: {
        id: "3"
      },
      fields: {
        numday: "3",
        day : "mercredi",        
        possiblehours: [
          "15h30",
          "16h30"        
        ],
        category: "0",
      }
    },
    {
      sys: {
        id: "4"
      },
      fields: {
        numday: "4",
        day : "jeudi",        
        possiblehours: [               
        ],
        category: "0",
      }
    },
    {
      sys: {
        id: "5"
      },
      fields: {
        numday: "5",
        day : "vendredi",        
        possiblehours: [             
        ],
        category: "0",
      }
    },
    {
      sys: {
        id: "6"
      },
      fields: {
        numday: "6",
        day : "samedi",
        
        possiblehours: [
          "12h00",
          "15h30",
          "16h30" 
        ],
        category: "0",
      }
    },

    /*PRESTATIONS ENCADREES*/
    {
      sys:{
        id: "7"
      },
      fields: {
        numday: "0",
        day: "dimanche",
        possiblehours: [
          "15h30",
          "16h30"
        ],
        category: "1",
      }
    },
    {
      sys: {
        id: "8"
      },
      fields: {
        numday: "1",
        day : "lundi",        
        possiblehours: [        
        ],
        category: "1",
      }
    },
    {
      sys: {
        id: "9"
      },
      fields: {
        numday: "2",
        day : "mardi",        
        possiblehours: [          
        ],
        category: "1",
      }
    },
    {
      sys: {
        id: "10"
      },
      fields: {
        numday: "3",
        day : "mercredi",        
        possiblehours: [
          "15h30",
          "16h30"        
        ],
        category: "1",
      }
    },
    {
      sys: {
        id: "11"
      },
      fields: {
        numday: "4",
        day : "jeudi",        
        possiblehours: [               
        ],
        category: "1",
      }
    },
    {
      sys: {
        id: "12"
      },
      fields: {
        numday: "5",
        day : "vendredi",      
        possiblehours: [
          "17h00",
         
        ],
        category: "1",
      }
    },
    {
      sys: {
        id: "13"
      },
      fields: {
        numday: "6",
        day : "samedi",        
        possiblehours: [
          "08h00",
          "12h30",
                  
        ],
        category: "1",
      }
    },

  ];
  
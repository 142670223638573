import React from 'react'

const AdminLink = ({className}) =>(
    
    <div className = {className}>
        <p><a href="mailto:admin@lesecuriesdulysse.club">Contacter l'administrateur du site</a></p>

    </div>


);
export {AdminLink}
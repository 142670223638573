import React, { Component } from 'react';
import {EyeButton, EyeSpan} from "../common"
import {FaEye, FaEyeSlash} from 'react-icons/fa';


/**
 * props :  name
 */

export default class WithEye extends Component {
    constructor(props){
        super(props)
    }
    state=({
        passwordType: "password",
   
    })


    

    render() {
     
        return (
        <EyeButton 
          type="button"
          name={this.props.name}
          onClick={this.props.onClick}
          passwordtype={this.props.passwordType}
          ispwd={this.props.isPwd}>
          <EyeSpan 
            ispwd = {this.props.ispwd}>
            {this.props.ispwd ? <FaEye/> : <FaEyeSlash/>}
            </EyeSpan>
        </EyeButton>
        )
    }
}

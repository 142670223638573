
import React from 'react';
import BabyPoney from './BabyPoney';


const ClearColor = "#9D609D";
const MiddleColor = "#77B82A"; //green

export default function BabyDesc() {
    return (
        <div>
           <span> Le Baby Poney, c'est une balade en mains avec votre enfant et l'un des poneys de l'élevage de Boileau.
               Choisissez d'abord le nombre d'enfants présents et leur âge.
               Cliquez ensuite sur le jour, puis l'heure de votre balade</span>
      
        <BabyPoney width="500" height="500" ClearColor={ClearColor} MiddleColor={MiddleColor}/>
        </div>
    )
}




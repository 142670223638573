import React, { Component } from 'react';
import { Navigate} from 'react-router-dom';
import {
    StyledForm, 
    StyledInput,      
    Label,
    LabelConditions,
    InputCheck,
    CheckWrapper,
    StyledInputwrapper,
    ReinitMarginTop,
    CgvButton
} from "../common";
import PwdInput from "./PwdInput";
import PoneyAccount from './PoneyAccount';
import {UserContext, withUser} from "../contexts/UserContext";
import {AuthMessageForSignUp} from "./AuthMessageForSignUp";
import '../App.css';
import {SubmitButton} from "./SubmitButton";
import connectionStrings from "../data/connectionStrings";
import axios from 'axios';
import {checkEmailAddress} from "../data/clientUtils";
import ModalCgv from './ModalCgv';



const SERVER = connectionStrings.SERVER;

class SignUpForm extends Component { 
    STATUSOPTION = {        
        AccessControlAllowOrigin : "*",
        xsrfCookieName: 'XSRF-TOKEN',
        xsrfHeaderName: 'X-XSRF-TOKEN',
        SameSite : 'None',
        Secure : 'true'            
       };
     
    
    constructor(props){
        super(props);       
        this.timerRef = React.createRef();       
        this._isMounted = false;        
    }
    
    state = {
     isValid : false,
     email: "", 
     username:"",
     password: "", 
     password_confirm: "",   
     passwordType: "password",
     emailValid: false,
     isvalidUsername: false,
     goToAccountConfirm: false,
     goToReinitConfirm: false,
     isInvalidPassWord: true,
     isChecked: false,
     cgvChecked: false,
     isSubmitted: false,
     openCgv: false,
     // googleCaptcha    
     //myToken: "",
     isLoading: false,
     compteur: 0, // init le compteur de demandes captcha
     isOk: false,     
     //times: -1 ,// compteur compodidupdate
     signUpMessage: "",
     captchaMessage: "",
     captchaApiKey: "",
     serverState: "",
     apiKeyByCat: "",
     emailMessage: "Email",
     usernameMessage: "Identifiant",
     mailIsSent: false,
    
     endOfEmailCheckQuery: false,
     endOfEmailCheckQuery2: false,
     endOfSignupQuery: false,
     endOfMailSendQuery: false,
     endOfUpdateUserQuery: false,
     user:{
        username: "",
        email: "",
        password: "",
        authenticated: false,
        bookings: [],
        name:"",
        phone:""
     }
    
     
    }

    
    handleChange =(event)=>{
        const {message, signUpMessage} = this.state;
        const {captchaMessage} = this.props.context;
        let input = "";
        this.props.context.resetGos();
       
        // reset messages
        if (event.target.name === "email"){
            this.setState({emailMessage: "Email"})
        }
        if (event.target.name === "username"){
            this.setState({usernameMessage: "Identifiant"}); 
        }
        
        
        this.setState({signUpMessage: this.resetMessage(signUpMessage)});
        this.setState({captchaMessage: this.resetMessage(captchaMessage)});
        this.props.context.setCaptchaMessage(captchaMessage);
        this.props.context.resetErrors();
        input = event.target.value;
        if (event.target.name === "email"){
            input = input.toLowerCase();
        }
        console.log(input);
        this.setState({[event.target.name]: input})
        
    }
    
    handleEmailCheck = async (mail) => {
        
        console.log ("le mail que l'on compare : " + mail);
        const result = this._isMounted && await checkEmailAddress(mail);
        if (result !== undefined){
            //console.log ("email valide : " + result)            
            this.setState ({emailValid : result});
            console.log ("handleEmailCheck emailValid : " + result);
            if (result === true){
            this.setState({emailMessage: "Email"}) 
        }else{
            this.setState({emailMessage: "Email non valide"});
        }
        return result;
        }else{
            this.setState({emailMessage: "Vérification du mail en cours..."});
        }
    }
    
    handleUsernameCheck =  (username) =>{
       
        const forbiddenUsername =   this.checkUsername(username);        
        if (forbiddenUsername !== undefined){
        this.setState ({isvalidUsername : !forbiddenUsername});
        console.log ("user isvalid : "+ username + " : " + this.state.isvalidUsername)
        console.log ("mot interdit : "+ username + " : " + forbiddenUsername);
        forbiddenUsername === true ?
        this.setState({usernameMessage : "Votre identifiant doit faire plus de trois caractères"})
        :
        this.setState({usernameMessage: "Identifiant"});
        
   
    }
    }

handleMouseLeave = async (event)=>{       
    let input= event.target.value;     
    if (event.target.name === "email"){
        input = input.toLowerCase();
    }     
    this.setState({[event.target.name]: input})       
    
    /*Check validity*/
    if (event.target.name === "email"){        
        const emailIsOk = this._isMounted && await this.handleEmailCheck(input);
        if (emailIsOk !== undefined){
            this.setState({endOfEmailCheckQuery: true});
            if (emailIsOk === true){
                this.setState({emailValid: true})
            }
        }
       
    }else{
        if (event.target.name === "username"){
            this.handleUsernameCheck(input);
        }
    }
    
}
    _getServerStatus = async () =>{
        console.log("on rentre dans getServerStatus  signUpFrom");        
        const CancelToken = axios.CancelToken;  
        let cancel;
        
        try {                
            // envoi du nouvel utilisateur en db
            const resp = this._isMounted && await axios.post(`${SERVER}/getserverstatus`                    
            ,this.STATUSOPTION,
            {cancelToken: new CancelToken(function executor(cancelParam) {
                // An executor function receives a cancel function as a parameter
                cancel = cancelParam;
            })}, {timeout: 3000}
            );
            if (resp !== undefined){                        
                console.log("dans le else, server response : " + resp.data);           
                if (resp.data !== undefined)
                {                    
                        console.log("retour serveurstatus ok " + resp.data.serverStatus);
                        cancel(); // désabonnement
                        return ({serverStatus : resp.data.serverStatus}); // 0 : local 1: dev 2/3 : prod
                }
            }else{
                this.setState({message: "...Chargement en cours"});               
                return ({error: "...Chargement en cours"});
            }         
            
            /*GESTION DES ERREURS*/
        }catch(error){         
            cancel(); // Désabonnement
            return ({error: "Un problème de communication avec le serveur est survenu, merci de réessayer ultérieurement"});               
        }
        
        /**
        * FIN POST captcha
        */ 
    }

    checkUsername(username){
        console.log("on est dans checkUsername")
        const forbiddenWords = ["putain","merde", "fuck", "sex", "viagra", "salop", "grognasse", "pute",
                                "salope", "fuckoff", "viagra", "gay", "Morgane Gay"];    
        const forbid = forbiddenWords.find(forbid => username === forbid);
        const isForbidden = forbid !== undefined || username.length <=2
        return isForbidden;
    }

    componentDidUpdate(prevState){        
       
    }

    componentDidMount(){
        this._isMounted = true;       
        this.setState({            
            emailValid : true,
            isvalidUsername : true,
            isInvalidPassWord : false,
            isChecked: true,
            cgvChecked: true 
        }); 
        this.timerRef.current = setTimeout(() =>this.props.context.resetErrors(), 2000);
        // to prevent auto complete
        this.setState({
            email: "",
            username:"",
            password_confirm:"",
            password:"",
            isLogOut: false // for db deconnect
        });
        //
        window.scrollTo(0, 0);
    }
      
        
    componentWillUnmount(){        
        this._isMounted = false;
       
        // mem leak
        if (this.timerRef.current){
            clearTimeout(this.timerRef.current);
        }
        
    }
    
     
    keyPressed(event) {
        console.log("dans keypressed : event : " + event.key)
        if (event.key === "Enter") {            
            event.preventDefault();           
        }
    }

    checkPwd(passwordparam){  
        return passwordparam.length > 5 ? true : false
    }

    
    submitForm = async (event) =>{  
        event.preventDefault();
        this.setState({isLoading: true});        
        this.setState({isSubmitted: true});
        console.log("formulaire soumis");   
        /**
         * double check mail address and username
         */      
        const {email, username} = this.state;
        
        if (email){            
            const emailIsOk = this._isMounted && await this.handleEmailCheck(email);
            if (emailIsOk !== undefined){
                this.setState({endOfEmailCheckQuery2: true});
                if (emailIsOk === true){
                    this.setState({emailValid: true});
                    // tout part de la vérification du mail  
                    if (username){
                        this.handleUsernameCheck(username);           
                    }                                
                    //suite
                    const isInvalidPassWord = (this.state.password !== this.state.password_confirm) || !this.checkPwd(this.state.password) || !this.checkPwd(this.state.password_confirm);
                    const isvalidUsername = this.state.isvalidUsername && this.state.isvalidUsername === true;
                    const captchaIsOk = this.props.context.captchaRes;  
                    const isChecked = this.state.isChecked && this.state.cgvChecked; 
                    let isValidUsername = this.props.comefrom === "reinitpwd" ? true : isvalidUsername;
                    const isOk = emailIsOk && !isInvalidPassWord && isChecked  && captchaIsOk && isValidUsername;
                    console.log( "ok on y va ... ou pas : " + isOk);        
                    
                    if (isOk) {                                    
                        if (this.props.comefrom === "home"){
                            
                            if (window.navigator.onLine){  
                                /*Récupération des informations saisies*/
                                const user = {
                                    username: this.state.username, 
                                    authenticated: true,
                                    email: this.state.email,
                                    password: this.state.password                    
                                }                
                                
                                try {
                                    const data =  this._isMounted && await this.props.context.signUp(user); // envoi en POST
                                    if (data !== undefined){ 
                                        this.setState({endOfSignupQuery: true});                                              
                                        // envoi du mail de confirmation
                                        if (data.canCreateUser === false){
                                            console.log("l'utilisateur existe déjà on ne peut pas le créer " + data.canCreateUser);
                                            this.setState({signUpMessage : "Cet utilisateur existe déjà"});
                                        }
                                        if (data.message){
                                            console.log("Erreur retour signUp : " + data.message);
                                            this.setState({signUpMessage: data.message});
                                        }
                                        if (data.user){
                                            try {
                                                const response = this._isMounted && await this.props.context.sendEmail(user.email);
                                                if (response !== undefined){
                                                    this.setState({mailIsSent: true});
                                                    this.setState({endOfMailSendQuery: true});
                                                    const logOutUser = this._isMounted && await this.props.context.logout();
                                                    if(logOutUser !== undefined){
                                                        this.setState({isLogOut:true});
                                                        console.log("user logout after signup : ", logOutUser);
                                                        this.setState({user: logOutUser});
                                                    }
                                                }
                                                // attendu true
                                            } catch (error) {                                    
                                                this.setState({signUpMessage: error});
                                                // on déconnecte l'utilisateur
                                                const logOutUser = this._isMounted && await this.props.context.logout();
                                                if(logOutUser !== undefined){
                                                    this.setState({isLogOut:true});
                                                    this.setState({user: logOutUser});
                                                }                                  
                                            }
                                        }
                                        
                                    }else{
                                        console.log("...création en cours : ");
                                        this.setState({signUpMessage: "...création en cours"});
                                    }
                                } catch (error) {                    
                                    this.setState({signUpMessage: error});
                                    
                                }               
                            }else{ // internet connection down
                                this.setState({signUpMessage: "Oups, il semblerait que vous ayez perdu votre connexion internet"});
                                
                            }
                            
                            /*UPDATE PASSWORD*/
                        }else if (this.props.comefrom === "reinitpwd"){
                            console.log("dans reinitpass")
                            const user = {                 
                                authenticated: true,
                                email: this.state.email,
                                password: this.state.password
                            }
                            console.log("de signup reinit, on envoie : " ,user)
                            try {
                                const theUser =  this._isMounted && await this.props.context.updateUser(user); // PUT
                                if (theUser !== undefined){
                                    this.setState({endOfUpdateUserQuery: true})                   
                                    if (theUser.error){
                                        console.log("erreur mAj : " + theUser.error);
                                    }
                                }else{
                                    
                                    console.log("user undefined");
                                }
                            } catch (error) {
                                console.log("erreur lors de la mise à jour du mot de passe : " + error);
                                
                            }
                        }
                        
                    }else{//noIsOk
                        console.log("pbm front validation on form submit")
                    }        
                    this.setState({isLoading: false});        
                }  
                
                
            }else{
                this.setState({emailValid: false});
            }
            
            
            
            
        }
}

 

    handleCheckBoxChange=(e)=>{
        if (e.target.name === "cgv"){
            console.log("clic sur cgv : ", e.target.checked);
            this.setState({cgvChecked: e.target.checked})
        }else{        
        console.log("enter in checkbox change : " +  e.target.checked)      
        this.setState({isChecked: e.target.checked})
    }
    }
    messageDiscriminate (message){
        
        const theType = typeof message;
        if (theType === "string" || theType === "undefined" || theType === null){
           //console.log("le message dans messageDiscriminate : " + message);
           return message;
        }else if (theType === "object"){          
           const properties = Object.values(message);
           const propTable = properties.map((prop, index)=><li id={index}>{prop}</li>);
           return propTable;
        }
        else{ // tableau
           console.log("le message dans messageDiscriminate tab: " + message[0]);
           return message.map((aMessage, index)=><li id={index}>{aMessage}</li>);           
        }
     }
     closeClick =()=>{
        console.log("close modal");
        this.setState({openCgv: false});
     }
     resetMessage (message){
        console.log("on rentre dans resetMessage");
        const theType = typeof message;

        if (theType === "string" || theType === "undefined" || theType === null){
           message = "";
           console.log("le message string reset : " + message);
           return message;
        }else if (theType === "object"){          
           const properties = Object.values(message);
           const propTable = properties.map((prop, index)=><li id={index}>{""}</li>);
           console.log("le message object reset : " + propTable);
           return propTable;
        }
        else{ // tableau
           
           return message.map((aMessage, index)=><li id={index}>{""}</li>);           
        }
     }

    render() {
        const {goTo, goToAc, updateError, captchaRes, captchaMessage, dbUser, isEmailSent} = this.props.context
        const {isSubmitted, email, password, password_confirm, emailValid, isvalidUsername, isChecked, cgvChecked} = this.state      
        const isInvalidPassWord = (password !== password_confirm) || this.state.isInvalidPassWord
        const isOk = emailValid && (this.state.password !== "") && (this.state.password_confirm !== "") && !isInvalidPassWord
        const isToHide =  (this.props.comefrom === "reinitpwd" ? true : false)
        const comeFrom = this.props.comefrom
        let {message, isDone} = this.props.context;
        const {signUpMessage, emailMessage, usernameMessage} = this.state;
        const endOfSignUpQueries = isEmailSent && dbUser !== undefined && isDone && (this.state.endOfEmailCheckQuery || this.state.endOfEmailCheckQuery2) && this.state.endOfSignupQuery && this.state.endOfMailSendQuery && this.props.context.endOfCaptchaQuery;
        
        const endOfUpdateUserQueries =  (this.state.endOfEmailCheckQuery || this.state.endOfEmailCheckQuery2) && this.state.endOfUpdateUserQuery && this.props.context.endOfCaptchaQuery;
        const updateOk = isSubmitted && goTo && captchaRes === "true" && endOfUpdateUserQueries;
        


    if (isSubmitted && goToAc && captchaRes === "true" && endOfSignUpQueries){

            console.log("dans le render, on va vers accconfirm")
            return (                
                <Navigate to="/accountconfirm" state={{user: this.state.user}} replace={true} />  
            )
        }else if (updateOk){            
            return(
                // on n'utilise pas le créneau horaire dans la confirmation de réinitialisation de mot de passe
                <Navigate to="/reinitconfirm" replace={true} 
                state={{ hourchoice : "",
                datechoice : "",
                user: this.state.user,
                email: this.state.email }}
                />
            )
       
    }else{        
        return(
                <> 
                
                <ReinitMarginTop istohide = {!isToHide}></ReinitMarginTop>
                <ModalCgv 
                    isOpen={this.state.openCgv}
                    closeClick={()=>this.closeClick}
                ></ModalCgv>  
                <div className="form-wrapper">
                             
                    <h1 className={isToHide ? "form-reinit" : "form-title"}>{this.props.title}</h1>             
                    <p className="space-recup-signup">&nbsp;</p>
                
                    <StyledForm signin={false} onSubmit={this.submitForm}>
                    <div className="poney-account-wrapper">
                    <PoneyAccount className="poney-account" color1={this.props.clearcolor} color2={this.props.middlecolor}  istohide={this.props.istohide} height="8em" width="8em"/>
                    </div>

                    <StyledInputwrapper>
                        <Label
                        tohideforeinit = {isToHide} isvalid = {usernameMessage === "Identifiant"}>{usernameMessage && usernameMessage }
                        </Label>
                            <StyledInput 
                            isvalid = {isvalidUsername}
                            tohideforeinit = {isToHide}
                            type="text" 
                            name="username" 
                            onChange={this.handleChange}
                            onBlur= {this.handleMouseLeave}
                            placeholder="Créez votre identifiant"
                            label = "Identifiant"                             
                            />
                    
                    <Label isvalid = {emailMessage && emailMessage !== "Email non valide"}>
                    {emailMessage}
                    </Label>                                        
                            <StyledInput
                            isvalid = {emailValid} 
                            type="email" 
                            name="email" 
                            value={email}
                            placeholder= "Votre email"
                            onBlur= {this.handleMouseLeave} 
                            onChange={this.handleChange}
                            tohideforeinit = {false}
                            required
                            
                            /> 
                            
                        <AuthMessageForSignUp isdone = {isDone} issubmitted={isSubmitted} message={this.messageDiscriminate(message)}/>
                            <Label 
                            isvalid = {!isInvalidPassWord}>{isInvalidPassWord ? "Merci de vérifier vos mots de passe" : "" }
                            </Label>
                            <PwdInput
                            isvalid = {!isInvalidPassWord}
                            password={password} 
                            name="password" 
                            onChange={this.handleChange}
                            placeholder="Votre mot de passe"                  
                            Label= "Mot de passe"                        
                            required      
                            />
                        
                            <PwdInput 
                            id="pass-ref"
                            isvalid = {!isInvalidPassWord}
                            password={password_confirm} 
                            name="password_confirm" 
                            onChange={this.handleChange}
                            placeholder="Confirmez votre mot de passe"                  
                            Label= "Confirmation mot de passe"                        
                            required                            
                            />
                             <Label isvalid = {!updateError && comeFrom === "reinitpwd"}>
                            {updateError}
                            </Label> 
                            <div id="conditions-ref" className='container-conditions'>
                                <Label tohideforeinit = {isToHide} className="accept" isvalid = {isChecked && cgvChecked}>{(isChecked === false || cgvChecked === false) ? "Merci d'accepter les conditions pour continuer" : "" }</Label>
                                <CheckWrapper>
                                    <InputCheck 
                                    type="checkbox"
                                    tohideforeinit = {isToHide}                        
                                    name= "accept"
                                    className="check"                       
                                    label= "Acceptation"
                                    onChange={(e)=>this.handleCheckBoxChange(e)}
                                    checked={isChecked}                                                        
                                    />                            
                                    <LabelConditions tohideforeinit = {isToHide}>
                                        J'accepte que mes données personnelles soient enregistrées dans le cadre strict des réservations de prestations
                                    </LabelConditions>
                                    <div>&nbsp;</div>
                                    </CheckWrapper>
                                    <CheckWrapper>
                                    <InputCheck 
                                        type="checkbox"
                                        tohideforeinit = {isToHide}                        
                                        name= "cgv"
                                        className="check"                       
                                        label= "Acceptation"
                                        onChange={(e)=>this.handleCheckBoxChange(e)}
                                        checked={cgvChecked}
                                                        
                                    />
                                    <LabelConditions tohideforeinit = {isToHide}>
                                        J'ai lu et j'accepte les conditions générales de vente de prestations
                                    </LabelConditions>                                    
                                    <CgvButton
                                    tohideforeinit = {isToHide}   
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        this.setState({openCgv: true})
                                        }}>Lire les CGV
                                    </CgvButton>                                
                                    </CheckWrapper>
                           
                            </div>
                           {/* ReCaptcha v3 component */}                           
                           
                            <Label 
                                isvalid = {!signUpMessage}>{signUpMessage && this.messageDiscriminate(signUpMessage)}
                            </Label>
                            <Label 
                                isvalid = {!captchaMessage}>{captchaMessage && this.messageDiscriminate(captchaMessage)}
                            </Label>
                         
                    </StyledInputwrapper>
                        <fieldset className="relative-wrapper-reinitpwd">
                        <SubmitButton type="submit" processing={this.state.isLoading} disabled={this.state.isLoading}>
                            {this.props.btnlabel}               
                        </SubmitButton>
                   
                        </fieldset>                                         
                    </StyledForm>                    
                 </div>
                </>
                
        )
      }
    }
  
}
export default withUser (SignUpForm)

import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import Presta from "../components/Presta";
import AuthUser from '../components/AuthUser';
import '../App.css';
import { UserContext } from '../contexts/UserContext';
import {Navigate, useLocation} from 'react-router-dom';
import { BookingContext } from '../contexts/BookingContext';
import RendezVous from '../components/RendezVous';
import {AuthMessage} from '../components/AuthMessage';




function Booking(props){    
   const userContext = useContext(UserContext);
   const bookingContext = useContext(BookingContext);   
   const [_isMounted, set_isMounted] = useState(false);
   const [response, setResponse] = useState(null);
   const isMailSent = userContext.isEmailSent; 
   const location = useLocation();
   const logoutTimer = useRef(null);
   const [isAuth, setIsAuth] = useState(userContext.isAuth !== -1 ? userContext.isAuth : location.state ?location.state.isAuth:3);
   console.log("isAuth booking : ", isAuth);
  
   const options = {
    state:{
    tsToDisable: bookingContext.tsToDisable, 
    validTs:bookingContext.timeStamps,
    validTsWithTimes:bookingContext.timeStampsWithTimes, 
    ...location.state
    }
}
    const noAuthUser = {
        id: -1,
        username: "",
        email: "",
        password: "",
        authenticated: false,
        bookings: [],
        name:"",
        phone:""
    }
   const[user, setUser] = useState(userContext.user.id !== -1 ? (userContext.user?userContext.user:userContext.user) : location.state &&location.state.user?(location.state.user?location.state.user:location.state.user):noAuthUser);
   const email = userContext.user.id !== -1 ? (userContext.user.email?userContext.user.email:userContext.user.mail) : location.state &&location.state.user?(location.state.user.mail?location.state.user.mail:location.state.user.email):noAuthUser.email;   
   console.log("user Booking : ", user);

   const handleClick = async (email) =>{   
    console.log("lien cliqué et mail récupéré pour envoi : " + email);
    
    try {
        const response = _isMounted && await userContext.sendEmail(email);
        if (response !== undefined){
            setResponse (true);
            console.log("response received");
        }  
         // attendu true
    } catch (error) {
      console.log("Erreur dans l'envoi du mail " + error);
      setResponse(false);
    }    
}


    //MOUNT
   useEffect(() => {   
    set_isMounted(true);
    async function logout() {
        const loggedOutUser={
            id: -2,
            username: "",
            authenticated: false,
            email: "",
            password: "",
            bookings: [],
            name:"",
            phone:""
        } 
        // db
        console.log("on rentre dans logout");
        try {
            const user = await userContext.logout();
            if (user !== undefined){
                //const options = {state: {isAuth:3, user: loggedOutUser}};
                console.log("not undefined ", user);
                setUser(user);
                setIsAuth(3);
            }
        } catch (error) {
            console.log("erreur logout : ", error);
        }
    }       
  
        /**Logout if idle time ICI 15 minutes 15*1000*60*/
        function resetTimer() {           
            clearTimeout(logoutTimer.current);            
            logoutTimer.current = setTimeout(() => {
                console.log("timeout"); 
                logout();
        }, 60*15*1000);
       
        }
    window.addEventListener("mousemove", resetTimer, false);
    window.addEventListener("click", resetTimer, false);
    window.addEventListener("scroll", resetTimer, false);
    window.addEventListener("keydown", resetTimer, false);
    window.addEventListener("keyup", resetTimer, false);
    window.addEventListener("touchmove", resetTimer, false);
    return()=>{
        clearTimeout(logoutTimer.current);
        document.removeEventListener("mousemove", resetTimer, false);
        document.removeEventListener("click", resetTimer, false);
        document.removeEventListener("scroll", resetTimer, false);
        document.removeEventListener("keydown", resetTimer, false);
        document.removeEventListener("keyup", resetTimer, false);
        document.removeEventListener("touchmove", resetTimer, false); 

    }
},   
   [])
   
   
   //UPADTE

   const reloadUser = useCallback(async()=>{
    const reloaded = await userContext.reloadUserAndBookings(email);
    if(reloaded !== undefined){
        console.log("user not undefinaned : ", reloaded);
        // ici on passe isAuth 2 au reload si email pas validé is auth = 1
        // voir si on ne peut pas améliorer à prochaine release
        if(reloaded.user){
            setUser(reloaded.user);
            setIsAuth(2);
            console.log("reloaded user : ", reloaded.user);
        }else{
            console.log("prob reloadUser");
        }
    }
   },[])
   useEffect(()=>{
   console.log("ue booking update");
    console.log("isAuth : ", isAuth);
    console.log("user: ", user);
    // chek unless pass userid to -2 !user || user.id === -1
    if(isAuth === -1){
        console.log("on doit reload le user et le isauth");
        reloadUser();
    }
    if(!bookingContext.timeStampsWithTimes || bookingContext.timeStampsWithTimes.length === 0){
        bookingContext.setReloadTs(true);
    }
   
   
    if(userContext.logoutClick && isAuth !== 3){      
        userContext.setIsAuth(3);
        setIsAuth(3);
        // resetLogoutCLick
        userContext.setLogoutClick(false);
    }
  
    
   }, [reloadUser,isAuth, bookingContext.reloadTs, bookingContext.reloadTsWithTimes,userContext.isAuth, location, userContext.logoutClick]);
      
        return (isAuth === 2 || isAuth === 1) ? (
            <>            
            
           {/* Pour centrer "nos rendez-vous" */}           
            
           <div className="header">
                <div className="left-container"></div>
                <div className="center-container-rdv">
                    <RendezVous pathcolor = "white"/>
                </div>
            <div className="right-container"></div>
            </div>
                <div id= "booking" className="booking-page">            
                    <div className="home">       
                        <AuthUser user={user}/> 
                        {/* L'utilisateur n'a pas encore validé son adresse mail */}                        
                       
                        <AuthMessage onClick={(e)=>handleClick(e, email)} ismailsent={isMailSent} isauth = {isAuth} email={email}/>
                    
                        <div className="page-title">Bienvenue dans votre espace réservation</div>
                            <p>Ici, vous pouvez réserver une activité ou modifier un rendez-vous déjà pris.</p>
                            <p>Si vous souhaitez une prestation encadrée par notre monitrice : balade, cours personnalisé, stage, activité périscolaire ou goûter d'anniversaire, rendez-vous dans la section <span className="pricing-body">Prestations encadrées.</span></p>
                            <p>Si vous êtes ici pour réserver une balade en mains avec votre enfant et l'un des poneys de l'élevage de Boileau, c'est la section <span className="pricing-body">Baby Poney</span> qui vous intéresse.</p> 
                            <p>La <span className="pricing-body">liste de vos réservations</span> vous permet quant à elle de visualiser et de modifier vos prochains rendez-vous.</p>                           
                        <Presta 
                        isAuth={isAuth} 
                        user={user} 
                        tsToDisable={bookingContext.tsToDisable} 
                        validTs={bookingContext.timeStamps} 
                        validTsWithTimes={bookingContext.timeStampsWithTimes} 
                        tsmessage={bookingContext.tsMessage}                       
                        tswtmessage={bookingContext.tsWtMessage}/>     
                        
                    </div>
                </div>  
                
                 {/* Background */}
                 <div className="booking-bckg">
                   {/*  <Listener user={user} set_isMounted={set_isMounted}/>   */}
                </div>   
            
            </>
            ):
            (
                <Navigate to="/logout" replace={true} state={{isAuth: isAuth}}/>
                
            )
            
        }
    
  
    export default Booking


import React from 'react';
import '../App.css';


const SelectInput=React.forwardRef((props, ref)=>(
    
    <select ref={ref} className={props.className} value={props.value} onChange={props.onChange}>
        <option></option> {/* Nothing preselected */}
                 {props.table.map((hour, key)=>(
                        <option value={hour} key={key}>
                            {hour}
                        </option>
                ))}
    </select>
    ));
export {SelectInput}
export default {
    /*LOCAL*/
 /* SERVER : "http://localhost:8080",
 CLIENT : "http://localhost:3000",
 SERVER_PORT : 8080,
 CLIENT_PORT : 3000, */

 /*SERVER*/
 SERVER : "/api/v1",
 CLIENT : "http://127.0.0.1:3000",
 SERVER_PORT : 5000,
 
 /*GOOGLE RECAPTCHA v3*/
 CAPTCHA_CLIENT_KEY_DEV : "6LeWbY0aAAAAANex1J3Ms53SuGAO9YU0Wqq_gOn9",
 CAPTCHA_CLIENT_KEY_LOCAL : "6LeWbY0aAAAAANex1J3Ms53SuGAO9YU0Wqq_gOn9",
 CAPTCHA_CLIENT_KEY_PROD : "6LeWbY0aAAAAANex1J3Ms53SuGAO9YU0Wqq_gOn9"
}
import React from 'react';
import {StyledInputStripe} from '../common';
import { 
    CardElement,
  
} from '@stripe/react-stripe-js';

import "../styles/StripeCardDetails.css";
import { GiScaleMail } from 'react-icons/gi';

const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#c4f0ff',
        color: 'purple',
        opacity: 0.5,
        fontWeight: 500,
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '0.9em',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {color: '#282c34'},
        '::placeholder': {color: 'grey'},
      },
      invalid: {
        iconColor: '#ffc7ee',
        color: '#ffc7ee',
      },
      
    },
  };



const Field  = (
    {
    
    label,
    id,
    type,
    placeholder,
    required,
    autoComplete,
    value,
    onChange,  
  }


)=>(
    <div className="FormRow">
        <label htmlFor={id} className="FormRowLabel">
            {label}
        </label>
    <StyledInputStripe
   
    id = {id}
    type = {type}
    placeholder = {placeholder}
    required = {required}
    autoComplete = {autoComplete}
    value = {value}
    onChange = {onChange}/>
    </div>
);

const CardField = ({onChange}) => (
    
    <div className="FormRow">
      <CardElement options={CARD_OPTIONS} onChange={onChange} />
    </div>
);



export  {Field, CardField}
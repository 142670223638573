import React, { Component } from 'react';
import Accordion from "../components/Accordion";
import BabyDesc from './BabyDesc';
import BabyContent from './BabyContent';
import BookingList from './BookingList';
import NoBabyContent from './NoBabyContent';
import baby from "../images/baby.png";
import cissou from "../images/medaillon_concours_00.png";
import {withBooking} from "../contexts/BookingContext";
import {FaAlignJustify} from 'react-icons/fa';
import ErrorWrapper from "./ErrorWrapper";








class Presta extends Component {
    constructor(props){
        super(props);
        this.props.context.resetGos();
        this._isMounted = false;
    }
    state = {
        validTs: [],
        prestaIsChosen : false,
        presta: ""      
        
    }

    async componentDidMount(){
        this._isMounted = true;        
        window.scrollTo(0, 0);
      
    }

  async componentDidUpdate(){
   console.log("user presta : ", this.props.user);
  }



     componentWillUnmount(){
        this._isMounted = false;
    }



    handleClick=(event)=>{
       this.setState({prestaIsChosen : true})
    }
    
    render(){
        
        const presta = [
            {
                title : "PRESTATIONS ENCADRÉES",
                subtitle : "Bienvenue sur l'espace PRESTATIONS ENCADRÉES des Écuries d'Ulysse",
                description : "Cours personnalisé seul ou à plusieurs? Balade encadrée par notre monitrice? Activité périscolaire ou goûter d'anniversaire? Choisissez votre prestation, le nombre de cavaliers puis le jour et l'heure de votre rendez-vous",
                content: <NoBabyContent isAuth={this.props.isAuth} user={this.props.user} start = {1} step={0} tsToDisable={this.props.tsToDisable && this.props.tsToDisable}  validTsStage = {this.props.validTs} messageStage={this.props.tsmessage}  validTs = {this.props.validTsWithTimes} message={this.props.tswtmessage}/>,
                presta: "1",
                type: "0",
                svg: <img src={cissou} className="acc_img"  width="120px" height="120px" alt="Prestations encadrées"/>
            },
            {
                title : "BABY PONEY",
                subtitle : "Bienvenue sur l'espace BABY PONEY des Écuries d'Ulysse",
                description : <BabyDesc/>,
                content: <BabyContent isAuth={this.props.isAuth} user={this.props.user} tsToDisable = {this.props.tsToDisable} start = {0} step={1} />,
                presta: "0",
                type: "0",                
                svg: <img src={baby} className="acc_img"  width="120px" height="120px" alt="Baby Poney"/>
            },            
            {
                title: "LISTE DE VOS PROCHAINES RÉSERVATIONS",
                subtitle: <ErrorWrapper><BookingList isAuth={this.props.isAuth} user={this.props.user} tsToDisable={this.props.tsToDisable && this.props.tsToDisable}  validTsStage = {this.props.validTs} messageStage={this.props.tsmessage}  validTs = {this.props.validTsWithTimes} message={this.props.tswtmessage}/></ErrorWrapper>,
                type: "-1",                
                svg: <FaAlignJustify className="nav-icon-presta" ></FaAlignJustify>
            }
        ]

     return(          
            <Accordion user={this.props.user} presta={presta}  />       
        )
    }       
}





export default withBooking(Presta)
import React from 'react';
import "../styles/StripeCardDetails.css";
import {StyledButtonCentered} from '../common';
import {StyledButton} from '../common';
import loadingGif from "../images/gif/loading-gear.gif";


const SubmitButton = ({processing, error, children, disabled}) =>(
  
        <StyledButtonCentered
        className={`SubmitButton ${error ? 'SubmitButton--error' : ''}`}
        type="submit"
        disabled={processing || disabled}>                       
            {processing ? <img src={loadingGif} className="loading" alt="chargement en cours..." /> : children }
        </StyledButtonCentered>
   
 
);
export {SubmitButton}
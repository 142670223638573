
import connectionStrings from '../data/connectionStrings';
import axios from 'axios';
import { compare } from 'bcryptjs';

const SERVER = connectionStrings.SERVER;


const bubbleSort = array => {
    const arrayLength = array.length
    let isSwapped
    do {
      isSwapped = false
      for (let i = 0; i < arrayLength - 1; i++) {
        if (array[i].numbered > array[i + 1].numbered) {
          const tempLeftValue = array[i].numbered;
          array[i].numbered = array[i + 1].numbered;
          array[i + 1].numbered = tempLeftValue;
          isSwapped = true;
        }
      }
    } while (isSwapped)
    return array;
}
let myTable = [{id: 0, numbered: 1200},
    {id: 0, numbered: 2500},
    {id: 1, numbered: 2},
    {id: 2, numbered: 5},
    {id: 3, numbered: 10},
    {id: 4, numbered: 30},
    {id: 5, numbered: 458}
]

function tableSort(table){
    console.log("mon tableau : ", myTable);
    return table.sort(function compare(a, b){
        if (a.numbered === b.numbered){ 
            return 0;
        }else{
            return a.numbered > b.numbered ? 1 : -1;
        }});
    }
   
   export function sortTimeStampsByCat(p_category, pValidTimestamps){
        const sortedTimeStamps = pValidTimestamps;       
       
        if (sortedTimeStamps !== undefined && p_category !== undefined){       
        const byCat = sortedTimeStamps.filter(ts => ts.category === p_category && ts.is_to_disable !== true);
        switch (p_category) {
            case 0:
                console.log("baby"); 
                break;
            case 1:
                console.log("presta externe"); 
                break;
            case 4:
                console.log("presta junior");
                break;
            case 5:
                console.log("presta senior");
                break;
            default:
                break;
        }
        return byCat;
       
    }
    }

/**
 * 
 * @param {array} bookings 
 * @returns {array} filtered by date bookings
 */
 export const filterBookings=(bookings)=>{    
    console.log("dans filterBookings : ", bookings) ;
    let filteredBookings=[];
    if (bookings && bookings.length>0){ 
    /*Réservation modifiable jusqu'à 24 heures après la réservation*/
    bookings.forEach(booking => {
        if (((new Date(booking.chosen_time_stamp)).getTime()+(24*3600000)) >= ((new Date().getTime()))){
            filteredBookings.push(booking)
        };
    });
   
    const numberedBookings = filteredBookings && filteredBookings.map(( (aBooking, index)=>{
        const filteredBooking = {...aBooking, numbered : (new Date(aBooking.chosen_time_stamp)).getTime()}; 
        return filteredBooking;
    }
    ))   
    const filteredTable = tableSort(numberedBookings);   
    return filteredTable;
    }
}

export const checkEmailAddress = async (emailAddress)=> { 
    console.log("on rentre dans chekmial ", emailAddress);
    let foundCorresp = false;
    let blackList = [];
    if(emailAddress !== undefined){
        if (emailAddress.length > 0){
            if (emailAddress !== undefined && emailAddress !== ""){            
                const regExp =  new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);           
                foundCorresp = regExp.test(emailAddress);           
            }
            let source = axios.CancelToken.source();
            try{
                const res = await axios.get(`${SERVER}/blacklist`,            
                {
                    cancelToken: source.token
                }
                );
                if (res !== undefined){
                    console.log("not undifiende");
                    blackList = res.data.blackList;
                    console.log("blackList : " + JSON.stringify(blackList));
                    source.cancel();
                    const forbiddenAddress = blackList.find(address=>
                        address === emailAddress);
                    const isForbiddenAddress = forbiddenAddress !== undefined ? true : false;
                    console.log("isForbidden : " + isForbiddenAddress);
                    console.log("email ok : " + foundCorresp && !isForbiddenAddress);
                return foundCorresp && !isForbiddenAddress ? true : false
                }else{
                    source.cancel();
                }
            }catch(error){
                console.log("erreur blackList : " + error);
            }
        }else{
            return false;
        }
    }
}
export const _checkEmailAddress = async (emailAddress)=> { 
    let foundCorresp = false;
    let blackList = [];
    if(emailAddress !== undefined){
        if (emailAddress.length > 0){
            if (emailAddress !== undefined && emailAddress !== ""){            
                const regExp =  new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);           
                foundCorresp = regExp.test(emailAddress);           
            }
            const CancelToken = axios.CancelToken;  
            let cancel;
            try{
                const res = await axios.get(`${SERVER}`+'/blacklist',            
                {cancelToken: new CancelToken(function executor(cancelParam) {
                    // An executor function receives a cancel function as a parameter
                    cancel = cancelParam;
                })
                },
                {timeout : 3000}
                )
                if (res !== undefined){
                    blackList = res.data.blackList;
                    console.log("blackList : " + JSON.stringify(blackList));
                    cancel();
                    const forbiddenAddress = blackList.find(address=>
                        address === emailAddress);
                    const isForbiddenAddress = forbiddenAddress !== undefined ? true : false;
                    console.log("isForbidden : " + isForbiddenAddress);
                    console.log("email ok : " + foundCorresp && !isForbiddenAddress);
                return foundCorresp && !isForbiddenAddress ? true : false
                }else{
                    cancel();
                }
            }catch(error){                
                console.log("erreur blackList : " + error);
            }
        }else{
            return false;
        }
    }
}
  /**
* Renvoie les strings de category
* @param {*} nCategory 
*/

export const setCategoryString=(nCategory)=>{
   let response="";        
   switch (nCategory) {
       case 0:
           response = "Baby";
           break;
       case 1:
           response = "Cours personnalisé";
           break;
       case 2:
           response = "Stage";
           break;
       case 3 :
           response = "Goûter d'anniversaire";
           break;
       case 4 :
           response = "Cours d'essai junior";
           break;
       case 5 :
           response = "Cours d'essai senior";
           break;
       default:
           response="";   
   }        
   return response;
}
export const setAmountByCatAndQuantity = (pCategory, pQuantity, pIsFullWeek) =>{
    let result = 1000;
    switch (pCategory) {
        case 0: // baby 
        result = 1000 * pQuantity;
        break;
        case 1: // cp 
        result = 4500 * pQuantity;
        break;
        case 2: // stage : if training, total of amount (60 euros / jour)
        
        if (pIsFullWeek !== undefined && pIsFullWeek === true){
            result = 5000 * pQuantity * 5; // number of days is not saved in db. For the list, and for the moment count is 5 days for fullweek
        }else{
            result = 6000 * pQuantity;
        }
        break;
        case 3: // goûter 
        result = 3000;
        break;
        case 4: // junior full
        result = 1900 * pQuantity;
        break;
        case 5: // senior
        result = 3900 * pQuantity;
        break;
        default:
        result = 0;
        break;
    }
    return result;
}
/**
 * 
 * @param {*} sNumber : string
 * @returns  a formatted string (plus 0 if < 10)
 */
export const formatNumber=(sNumber)=>{
    const iNumber = parseInt(sNumber, 10);
    if(iNumber < 10){
        return "0" + sNumber
    }else{
        return sNumber;
    }
}
/**
 * 
 * @param {sHour: string} sHour 
 * @returns sHour: string
 */
export const formatUnits=(sHour)=>{
    const zero = "0";       
    let result;
    console.log(sHour.toString().length);
    if (sHour.length < 2){
        result = zero.concat(sHour);
    }else{
        result = sHour;
    }
    return result;
}
/**
 * 
 * @param {*} date 
 * @returns Date formatée à 23h59min59s
 */
export const setHourForCalendarDate=(date)=>{
    /* const todayHour = formatNumber(new Date().getHours().toString());
    const todayMin = formatNumber(new Date().getMinutes().toString());
    const todaySec = formatNumber(new Date().getSeconds().toString());
    const todayHourString = todayHour + ":" + todayMin + ":" + todaySec; */
 
    // Give today hour to date of the calendar
    let dateString = date.toString();
    dateString = dateString.replace("00:00:00", "23:59:59");    
    const formatedDate = new Date(dateString);    
    return formatedDate;
}
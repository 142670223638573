import React, { Component } from 'react';
import SignUpForm from '../components/SignUpForm';
import {checkEmailAddress} from "../data/clientUtils";

export default class info extends Component{

    clearColor ="grey" ;
    middleColor = "#77B82A";

constructor(props){
    super(props);
}




render(){
   

    return(
    <>
    <div className="reinit-page">
      <SignUpForm comefrom="reinitpwd" checkEmailAddress={checkEmailAddress} istohide={true} title = "Bienvenue sur la page de réinitialisation de votre mot de passe" btnlabel = "Confirmer mes changements" middlecolor={this.middleColor} clearcolor={this.clearColor} />
      </div>
    </>
    )
}
}



import React from "react";
import {AiOutlineClose} from 'react-icons/ai';
import CgvDetails from "./CgvDetails";

const ModalCgv=(props)=> {

return (
    props.isOpen ? 
    <div className="modal-div" onClick={props.closeClick()}>
        <div className="cgv-div">
            
            <button className="close-button" onClick={props.closeClick()}>
          
             <AiOutlineClose/>
            </button>
          
        <h2 className="cgv-title"><span className="first-letter">C</span><span>ONDITIONS</span> 
         &nbsp;<span className="first-letter">G</span><span>ÉNÉRALES</span>
         &nbsp;<span className="first-letter">D</span><span>E</span> 
         &nbsp;<span className="first-letter">V</span><span>ENTE</span>
        </h2>
        <h2>APPLICATION DE RÉSERVATIONS EN LIGNE
            <span className="no-cap">&nbsp; lesecuriesdulysse.club</span>
        </h2>
        <span>Les présentes conditions générales portent sur les réservations 
        de prestations en ligne proposées par l'application &nbsp;
        <span className="address-club">lesecuriesdulysse.club</span>&nbsp; 
        accessible via l'onglet</span><span className="address-club">&nbsp;Réservations </span>
        du site  <a href="https://lesecuriesdulysse.fr" className="address-club"><span className="address-club">&nbsp;lesecuriesdulysse.fr.</span></a>
        
        {/* 01- Prestataire */}
        <CgvDetails 
            titleH3=" 01 - Le prestataire"
            titleDetail='Nom et prénom' 
            spanDetail='Caroline ORSINI DE MARI'
        />
        <CgvDetails 
            titleDetail='Adresse de domiciliation' 
            spanDetail='421 chemin des Perrous - 31620 BOULOC'
        />
        <CgvDetails 
            titleDetail='Téléphone' 
            spanDetail='06 75 86 21 93'
        />
        <CgvDetails 
            titleDetail='Adresse mel' 
            spanDetail={<a href= "mailto:contact@lesecuriesdulysse.fr"><span>contact@lesecuriesdulysse.fr</span></a>}
        />
        <CgvDetails 
            titleDetail='TVA Intracommunautaire' 
            spanDetail="FR65405283995"
        />
        {/* 02- Le client */}
         <CgvDetails 
            titleH3=" 02 - Le client"           
            spanDetail="Toute personne physique souhaitant réserver une des prestations externes proposées par les Écuries d'Ulysse : Baby-poney (balade en main avec un poney) ou prestations encadrées par un moniteur (stage, cours ou balade, anniversaire)."
        />
        <CgvDetails 
            titleH3="03 - Zones géographiques concernées par la vente de prestations" 
            spanDetail="Pour l'activité baby poney des circuits sont proposés aux alentours des Écuries."
            spanDetail02="Pour les activités encadrées, la pratique se fait au sein des installations ou en extérieur."
        />
        <CgvDetails 
            titleH3="04 - Services : conditions et durée" 
            spanDetail="L'application propose des réservations en ligne avec paiement en ligne de tout ou partie de la prestation pour les activités baby-poney, cours, balades, stages et anniversaires. 
            Les stages et les cours (cours personnalisés, cours d'essai junior, cours d'essai senior) sont réglés directement en ligne et ne font pas l'objet d'une facturation supplémentaire."
            spanDetail02="Pour les prestations récurrentes (baby poney, cours, balades et anniversaires) les créneaux sont ouverts par cycles de trois mois. "
            spanDetail03="Pour les stages, les créneaux sont ouverts en période de vacances scolaires ; ils correspondent aux dates des stages du club."
        />
        <CgvDetails 
            titleH3="05 - Prix" 
            spanDetail="Pour les stages, le prix est calculé en fonction de la durée (en jours) et du nombre de cavaliers. Pour les cours (cours d'essai junior et senior | cours personnalisés) et les stages, le paiement complet se fait comptant en ligne."
            spanDetail02="Pour les prestations baby-poney et anniversaire, les arrhes sont calculées en fonction du type de prestation et du nombre de cavaliers. Ce montant est ensuite déduit du règlement final de la prestation. "
            spanDetail03="Le prix est affiché avant validation de la réservation au même titre que l'heure et la date du rendez-vous. S'il s'agit d'arrhes, la mention est ajoutée. "
        />
         <CgvDetails 
            titleH3="06 - Conditions de paiement" 
            spanDetail="Pour les stages et les cours (cours d'essai junior et senior, cours personnalisés), le règlement intégral se fait comptant par carte bancaire à la validation de la réservation et est débité immédiatement. 
            Il en est de même pour les arrhes des autres prestations."
        />
         <CgvDetails 
            titleH3="07 - Espace et compte client" 
            spanDetail="La création de compte requiert le minimum d'informations nécessaires à une réservation : le nom, le pseudo (afin de personnaliser l'interface du client), un numéro de contact et une adresse mail."
        />
         <CgvDetails 
            titleH3="08 - Processus de commande" 
            spanDetail="Le client crée un compte utilisateur, le valide, puis se connecte pour effectuer une réservation."
            spanDetail02="Il choisit la prestation parmi celles proposées : baby poney, prestations encadrées (balades, cours, anniversaire ou stage)."
            spanDetail03="Il renseigne ensuite le nombre de personnes concernées, l'heure et la date de son rendez-vous ; puis, suivant la prestation désirée, il choisit l'âge de chaque cavalier (pour les baby poney) ou le niveau équestre (pour les cours ou les stages)."
            spanDetail04="Un résumé de son rendez-vous et du montant qui sera payé en ligne lui est proposé."
            spanDetail05="Il renseigne les informations nécessaires au paiement par carte bleue (numéro, CVC, date d'expiration) puis valide la réservation."
            spanDetail06="Une référence de paiement lui est fournie par l'application et un résumé de sa réservation est envoyé par mail. Le client a également la possibilité de consulter sa réservation et de la modifier via son espace client."
        />
         <CgvDetails 
            titleH3="09 - Modalités de fourniture des prestations" 
            spanDetail="Pour l'activité baby-poney, le poney est déjà préparé. 
            Le client se rend aux Écuries le jour et à l’heure de son rendez-vous. Le personnel des Écuries l’accueille et lui rappelle les différents circuits dont il a déjà été informé à la réservation ainsi que les règles de sécurité pendant la tenue du poney.
            Il peut effectuer la balade durant le temps de son choix, et règle le solde de la prestation à son retour en fonction."
            spanDetail02="Pour les prestations encadrées, le client se présente dix minutes avant l'heure de la prestation.
            Le cours ou la balade dure une heure."
            spanDetail03="Pour les stages, les créneaux sont établis à la journée. Si le stage dure 5 jours, le client a la possibilité de réserver le stage complet par une case à cocher."
            spanDetail04="Pour les anniversaires, la prestation débute à l'heure du rendez-vous."
        />
        <CgvDetails 
            titleH3="10 - Annulation de commande " 
            spanDetail={<span>Le client peut annuler la commande par mail auprès de <a href= "mailto:contact@lesecuriesdulysse.fr">contact@lesecuriesdulysse.fr</a></span>}
            spanDetail02=" Le montant réglé en ligne est non remboursable car le principe a été mis en place dans le but que le rendez-vous soit honoré."
            spanDetail03=" En revanche, toute réservation peut être modifiée.
            "
        />
        <CgvDetails 
            titleH3="11 - Préavis de modification et de réclamation" 
            spanDetail="Les rendez-vous sont modifiables jusqu'à trois heures avant le début de la prestation."
        />
        <CgvDetails 
            titleH3="12 - Modalités de modification de commande (changement de rendez-vous)" 
            spanDetail="Le client se rend dans la liste de ses prochaines réservations, choisit le nouveau créneau (la date puis l'heure) en face du récapitulatif de son ancien rendez-vous et valide sa modification."
            spanDetail02="Un message de confirmation lui est alors affiché et un nouveau mail lui est envoyé."
        />
        <CgvDetails 
            titleH3="13 - Modalités de réclamation" 
            spanDetail="Les Écuries peuvent procéder à un remboursement en cas de problème technique indépendant de la volonté des deux parties."
            spanDetail02="La référence de paiement fournie au client lors de la réservation lui sera alors demandée."
            spanDetail03={
                <span>
                En outre, la responsabilité du Prestataire ne saurait être engagée pour tous les inconvénients ou dommages inhérents à l’utilisation d'Internet, notamment une rupture de service, une intrusion extérieure ou une attaque virale. 
                L'application de réservations répond à une obligation de moyens et non de résultat. À ce titre, le Prestataire met tout en oeuvre pour qu'elle soit fonctionnelle et intuitive. 
                Si, malgré tous les efforts engagés, l'utilisateur ne parvient pas à utiliser le service, il est invité à contacter directement le Prestataire ou l'administrateur du site.
                Il ne pourra pas être reproché au Prestataire un défaut lié à une mauvaise utilisation de l'application ; si un problème technique intervient : en cas de trop perçu, le Prestataire s'engage à effectuer un remboursement au plus tôt de la différence, 
                sans autre indemnité supplémentaire.
                </span>
            }
        />
         <CgvDetails 
            titleH3="14 - Traitement des données personnelles" 
            spanDetail="Les données personnelles des clients sont collectées pour l'ouverture du compte et la vente des prestations."
            spanDetail02="Afin de pouvoir réaliser une réservation, les données personnelles suivantes sont collectées: "
            lstDetails02={["- le nom du client, ","- son numéro de téléphone, ", "- son adresse mail."].map((anElt, index)=>(<p key={index}><span>{anElt}</span></p>))}
            spanDetail03={
            <span>
                Le module <span className="stripe"> Stripe </span>
                utilisé pour le paiement en ligne se réserve le droit 
                de collecter les informations liées à la carte de paiement 
                afin de pouvoir effectuer un remboursement en cas de problème 
                technique et d'effectuer un suivi personnalisé du règlement.
                
                </span>}
            spanDetail04 = {
            <span>
                
                Se rapprocher des conditions générales de vente de <span className="stripe"> Stripe </span>
                pour plus de détails : <a href = "https://stripe.com/fr/legal"><span className="address-club">stripe.com/fr/legal. </span></a>
                <span>Les Écuries d'Ulysse ne peuvent pas être tenues responsables des événements fortuits liés à cette détention.</span>
  
            </span>}
        />
        <CgvDetails 
            titleH3="15 - Responsable de traitement des données personnelles" 
            spanDetail="Caroline ORSINI DE MARI."
        />
        <CgvDetails 
            titleH3="16- Moyens dont dispose le client pour supprimer ses données personnelles" 
            spanDetail= 
            {
                <p>       
                <span>
                Les données personnelles du client peuvent être supprimées par simple demande 
                auprès de {<a href = "mailto:contact@lesecuriesdulysse.fr">contact@lesecuriesdulysse.fr</a>}
                </span>
                </p>
            }/>
            <CgvDetails 
            titleH3="17- Service de médiation" 
            spanDetail= 
            {
                <span>       
                Le service de médiation des Écuries d'Ulysse est fourni par la Fédération Française d'Équitation à laquelle les Écuries sont affiliées.  
                Site internet :  {<a href = "https://www.ffe.com/"><span className="address-club">ffe.com</span></a>}
                </span>
            }
        />
        <CgvDetails 
            titleH3="18- Date de mise en vigueur des CGV" 
            spanDetail= "Les conditions générales de vente sont valables dès la mise en service de l’application avec effet rétroactif au 15 avril 2021."            
        />
       
        
       
        
        </div>
    </div>    
     : 
     <div className="closed-div">
       
     </div> 
)
}
export default ModalCgv
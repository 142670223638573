import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import {BrowserRouter as Router} from 'react-router-dom';
import {UserProvider} from "./contexts/UserContext";
import {BookingProvider} from "./contexts/BookingContext";


const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(

  <UserProvider>
    <BookingProvider>
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>
   </BookingProvider>
  </UserProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { Component } from 'react';
import Accordion from "../components/Accordion";
import Bookings from './AdminBookings';
import baby from "../images/baby.png";
import stage from "../images/medaillon_equipeFrance.png";
import cissou from "../images/medaillon_concours_00.png";
import corse from "../images/medaillon_corse.png";
import {withBooking} from "../contexts/BookingContext";
import Days from "./AdminDays";
import connectionStrings from "../data/connectionStrings";
import axios from "axios";
import { Label } from '../common';
import AdminRDPToDisable from './AdminRDPToDisable';
import AdminRangeDatePicker from './AdminRangeDatePicker';
import {FaAlignJustify} from 'react-icons/fa';
import CollectivDays from './AdminCollectivDays';

const REF_DATE = new Date();

const presta = [
    {
        title : "DÉSACTIVER DES CRÉNEAUX PONCTUELS",
        subtitle : <AdminRDPToDisable/>,
        description : "",
        content: "",
        presta: "-1", // non vide
        svg: <img src={corse}  width="120px" height="120px" alt="Baby Poney"/>
    },

    {
        title: "MODIFIER LES CRÉNEAUX COURS ET BALADE",
        subtitle: <CollectivDays category={1}/>,
        description : "",
        content: "",
        presta: "1",
        svg: <img src={cissou} width="120px" height="120px" alt="Prestations encadrées"/>
    },
    {
        title : "MODIFIER LES CRÉNEAUX BABY",
        subtitle : <Days category={0}/>,
        description : "",
        content: "",
        presta: "0",
        svg: <img src={baby}  width="120px" height="120px" alt="Baby Poney"/>
    },
    {
        title : "MODIFIER LES CRÉNEAUX DE STAGES",
        subtitle : <AdminRangeDatePicker/>,
        description : "",
        content: "",
        presta: "2",
        svg: <img src={stage}  width="120px" height="120px" alt="Baby Poney"/>
    },
    
    {
        title: "LISTE DE VOS PROCHAINS RENDEZ-VOUS",
        subtitle: <Bookings/>,
        svg: <FaAlignJustify className="nav-icon-presta" ></FaAlignJustify>
    },

];



class AdminPresta extends Component {
    constructor(props){
        super(props);
        this.props.context.resetGos();
        this._isMounted = false;            
        this.SERVER = connectionStrings.SERVER;


    }
    state = {
        prestaIsChosen : false,
        presta: "",
        message:"" 
    }

    async componentDidMount(){
        this._isMounted = true;             
        
    }
        
    async componentDidUpdate(){
        console.log("adminuser : ", this.props.user);
        console.log("adminpresta : ", presta);
    }
  
 

    getSortedTimeStamps = async ()=>{
        const CancelToken = axios.CancelToken;
        let cancel;
        try {
              const resp = await axios.get(`${this.SERVER}/orderedts`, {
                headers:{'sortedtimestampsheader': 'sortedtimestamps'}
                },       
              {cancelToken: new CancelToken(function executor(cancelParam) {
                    // An executor function receives a cancel function as a parameter
                    cancel = cancelParam;
              })});
              if (resp !== undefined){
                  
                    if(resp.data.error){
                          console.log("Erreur pendant le chargement des créneaux : " + resp.data.error);
                          cancel();
                          return {message: "Une erreur est survenue pendant le chargement des créneaux"};
                    }else{
                          console.log("les timeStamps récupérés : " + resp.data.timestamps.id);
                          cancel();
                          return {timeStamps : resp.data.timestamps};
                    }
              }else{
                    console.log("... chargement en cours");
                    return {message : "... chargement en cours"};
              }
        } catch (error) {
              console.log("erreur dans la récupération des créneaux : " + error);
              cancel()
              return {message : "Erreur dans la récupération des créneaux : " + error};
        }
  }





    handleClick=(event)=>{
       this.setState({prestaIsChosen : true})
    }
    
    render(){
    const {message} = this.state
    
  



     return( 
         <>
            <Accordion user={this.props.user} presta={presta}/>
            <Label isvalid={false}>{message}</Label>
        </>
        )
    }       
}
export default withBooking(AdminPresta)
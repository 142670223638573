import React, { Component } from 'react';
import AdminLessonsDayCard from './AdminLessonsDayCard';
import '../App.css';
import {StyledFormCardsDaysAdmin, Label} from '../common';
import addMilliseconds from 'date-fns/addMilliseconds';
import axios from 'axios';
import connectionStrings from '../data/connectionStrings';
import {sortTimeStampsByCat} from '../data/clientUtils';

const SERVER = connectionStrings.SERVER;

export default class CollectivDays extends Component {



constructor(props){
    super(props);
    this._isMounted = false;
 
    

    this.state = {   
        validityDate:""  ,
        message:"",
        finalTimeStamps:[],
        validTimestamps: [],
        hours: [],
        tempTimes: [],
        recievedTimeStamps:[],
        selectedLesson: -1, // init à rien,
        lessClicks: 0,
        plusClicks: 0
     
    }
}
async componentDidUpdate(prevProps, prevState){
    
}
async reloadTimeStampsWithTimes(){
    if (!window.navigator.onLine){
        this.setState({message: "Oups, il semblerait que vous ayez perdu votre connexion internet"});
    }else{
        const resp = this._isMounted && await this.getTimeStampsWithTimes();
        if (resp !== undefined){
            if (resp.message){
                this.setState({message: "Aucun créneau disponible"});
            }else{ // récupération des heures
                if(resp.timestamps !== undefined){ 
                this.setState({validTimestamps: resp.timestamps});    
            }
        
        }
        }else{
            this.setState({message : "...Chargement en cours"});
        } 
    }
}
async componentDidMount(){
    this._isMounted = true;
    await this.reloadTimeStampsWithTimes();
}



/**
 * @returns 
 */
getTimeStampsWithTimes = async ()=>{
    const CancelToken = axios.CancelToken;
    let cancel;
    try {
          const resp = await axios.get(`${SERVER}/admintimes`, {
            headers:{'timesheader': 'times'}
            },            
            {cancelToken: new CancelToken(function executor(cancelParam) {
                // An executor function receives a cancel function as a parameter
                cancel = cancelParam;
          })});
          if (resp !== undefined){              
                if(resp.data.error){
                      console.log("Erreur pendant le chargement des heures : " + resp.data.error);
                      cancel();
                      return {message: "Une erreur est survenue pendant le chargement des heures"};
                }else{
                      console.log("les heures récupérées : " , resp.data.timestamps);
                      cancel();
                      return {timestamps : resp.data.timestamps};
                }
          }else{
                console.log("... chargement en cours");
                return {message : "... chargement en cours"};
          }
    } catch (error) {
          console.log("erreur dans la récupération des heures : " + error);
          cancel()
          return {message : "Erreur dans la récupération des heures : " + error};
    }
}

handleValidityDate=()=>{
    // 3 mois après la date courante
    const milliDatePast3 = addMilliseconds(new Date(), 7776000000); 
    return milliDatePast3.toLocaleDateString();
}

componentWillUnmount(){
    this._isMounted = false;
}
 onRadioChange=(e)=>{
    let reloadedTimeStamps;
    const theCategory = e.target.id;

    let presta = -1; // init
    console.log("la categorie cours co : " + theCategory);
    switch (theCategory) {
        case "junior":
            presta = 4;
            break;
        case "senior":
            presta = 5;
            break;
        case "external":
            presta = 1;
            break;
        default:
            presta = this.props.category;
            break;
    }
    console.log("presta id : ", presta);
    this.setState({selectedLesson: presta});
    // reload la liste des créneaux si modification
    if (this.state.lessClicks > 0 || this.state.plusClicks > 0){
        this.setState (
            {
            plusClicks: 0,
            lessClicks : 0
            })
        this.reloadTimeStampsWithTimes().then(
            reloadedTimeStamps = sortTimeStampsByCat(parseInt(presta, 10), this.state.validTimestamps)
            
        )
    }else{
        reloadedTimeStamps = sortTimeStampsByCat(parseInt(presta, 10), this.state.validTimestamps);
        console.log("just change");
    }
    
    console.log("ts on radio : ", reloadedTimeStamps);
    this.setState({finalTimeStamps: reloadedTimeStamps});

}






setLessClicks = ()=>{
   
    this.setState(
        (prevState)=>{
            return {lessClicks: prevState.lessClicks + 1 }
        }, ()=>{console.log("lessClicks : ", this.state.lessClicks)}
    )
}
setPlusClicks = ()=>{
  
    this.setState(
        (prevState)=>{
            return {plusClicks: prevState.plusClicks + 1 }
        }, ()=>{console.log("plusClicks : ", this.state.plusClicks)}
    )
}

    render(){
       const finalTimeStamps = this.state.finalTimeStamps !== undefined ? this.state.finalTimeStamps : "... chargement en cours"
       console.log("timestamps passés : ", finalTimeStamps);
       const message = this.state.message
    
      
    return(
        <> 
        <Label isValid={false}>{message}</Label>
        <div className='radio-g-lessons'>
            <div className='radio-lessons'><input type="radio" name='collectiv-lessons' id='junior' checked={this.state.selectedLesson === 4} onChange={this.onRadioChange}></input><label> Cours d'essai junior</label></div>
            <div className='radio-lessons'><input type="radio" name='collectiv-lessons' id='senior' checked={this.state.selectedLesson === 5} onChange={this.onRadioChange}></input><label>Cours d'essai senior</label></div>
            <div className='radio-lessons'><input type="radio" name='collectiv-lessons' id='external' checked={this.state.selectedLesson === 1} onChange={this.onRadioChange}></input><label>Prestations externes</label></div>
        </div>
        <StyledFormCardsDaysAdmin>       
        {finalTimeStamps && finalTimeStamps.map((ts)=>      
            <AdminLessonsDayCard 
            key={ts.id}
            onLessClick={this.setLessClicks}
            onPlusClick={this.setPlusClicks}
            num_day = {ts.num_day}
            hours= {ts.times}
            category={this.state.selectedLesson}
            selectedHour={this.state.selectedHour}
            selectedMinute={this.state.selectedMinute}
           
            /> 
              
            )// fin map
        }
        </StyledFormCardsDaysAdmin>
        </>
        )
            
    }  
}


import React, {useState, useContext, useEffect, useCallback} from 'react'
import Stripe from './Stripe';
import {BookingContext} from "../contexts/BookingContext";
import {UserContext} from "../contexts/UserContext";
import {Label, StyledEvent} from "../common";
import { useLocation } from 'react-router-dom';

const INITIALSTATE = [];

function NoBabyContent({...props}) {
  const [selectedOption, setSelectedOption] = useState("");
  const [items, setItems] = useState([]);
  const [_isMounted, set_isMounted] = useState(false);
  const [daysToDisable, setDaysToDisable] = useState([]);
  const [myError, setMyError] = useState("")
  const bookingContext = useContext(BookingContext);
  const userContext = useContext(UserContext);
  const [messageStorage, setMessageStorage] = useState([]);
  const [defaultSelection, setDefaultSelection] = useState('');
  const [eventSelection, setEventSelection] = useState(false);
  const [bookingsByCat, setBookingsByCat] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState('');
  const [schoolIsSelected, setSchoolIsSelected] = useState(false);
  const [isOvershot, setIsOvershot] = useState(false);
  const [bookingLoaded, setBookingLoaded] = useState(false);
  const [reloadedTs, setReloadTs] = useState(props.validTs);
  const [reloadedTrainingTs, setReloadedTrainingTs] = useState(props.validTsStage);
  const [remindMe, setRemindMe] = useState(false);

  const bookingSentance = "Merci de sélectionner un cours adapté à votre niveau";
  const bookingInfos = [
    {
      categoryId: 1, levels: [
        
        {lesson: "Éveil aux poneys 2 | 4 ans (45 minutes)", day: "samedi", hour: ["12h00"]},
        {lesson: "Pitchouns | débutants | galops poneys 4 à 7 ans ", day: "mercredi", hour: ["14h00"]},
        {lesson: "", day: "samedi", hour: ["10h00"]},    
        {lesson: "Débutants | galop 1 - 7 ans et plus ", day: "mercredi", hour: ["15h00"]},
        {lesson: "", day: "samedi", hour: ["11h00"]},
        {lesson: " 10 ans et plus ", day: "samedi", hour: ["14h00"]},
        {lesson: "Galop 1 | 2 ", day: "samedi", hour: ["15h00"]},
        {lesson: "Galop 2 | 3 ", day: "mercredi", hour: ["16h00"]},
        {lesson: "", day: "samedi", hour: ["16h00"]},
        {lesson: "Galop 4 | 7 compétition ", day: "mercredi", hour: ["17h00"]},
        {lesson: " ", day: "samedi", hour: ["17h00"]},
        {lesson: "Cours particulier", day: "mardi", hour: ["9h00", "10h00", "11h00", "12h00", "13h00", "14h00", "15h00", "17h00"]},
        {lesson: "", day: "mercredi", hour: ["9h00", "10h00", "12h00","13h00"]},
        {lesson: "", day: "jeudi", hour: ["9h00", "10h00", "12h00"]},
        {lesson: "", day: "vendredi", hour: ["9h00", "10h00", "12h00"]},
        {lesson: "Cours adulte", day: "mercredi", hour: ["11h00"]},
        {lesson: "", day: "jeudi", hour: ["11h00"]},
        {lesson: "", day: "vendredi", hour: ["11h00"]},
        {lesson: "", day: "samedi", hour: ["9h00"]}
       
      ]
    }, /**Adultes */
      {categoryId: 5, levels: [
        {lesson: "Cours adulte", day: "mercredi", hour: ["11h00"]},
        {lesson: "", day: "jeudi", hour: ["11h00"]},
        {lesson: "", day: "vendredi", hour: ["11h00"]},
        {lesson: "", day: "samedi", hour: ["9h00"]},
      ]
    },/** enfants */
      {categoryId : 4, levels: [
        {lesson: "Éveil aux poneys 2 | 4 ans (45 minutes)", day: "samedi", hour: ["12h00"]},
        {lesson: "Pitchouns | débutants | galops poneys 4 à 7 ans ", day: "mercredi", hour: ["14h00"]},
        {lesson: "", day: "samedi", hour: ["10h00"]},    
        {lesson: "Débutants | galop 1 - 7 ans et plus ", day: "mercredi", hour: ["15h00"]},
        {lesson: "", day: "samedi", hour: ["11h00"]},
        {lesson: " 10 ans et plus ", day: "samedi", hour: ["14h00"]},
        {lesson: "Galop 1 | 2 ", day: "samedi", hour: ["15h00"]},
        {lesson: "Galop 2 | 3 ", day: "mercredi", hour: ["16h00"]},
        {lesson: "", day: "samedi", hour: ["16h00"]},
        {lesson: "Galop 4 | 7 compétition ", day: "mercredi", hour: ["17h00"]},
        {lesson: " ", day: "samedi", hour: ["17h00"]}
      ]
    }
    ]
    function selectInfoByCat(pCat){
      console.log("on rentre dans selectInfoByCat, cat : ", pCat);
     if (pCat !== undefined){
          const currentInfo =  bookingInfos.filter(anInfo=>anInfo.categoryId === pCat);
          if(currentInfo !== undefined){
            console.log("currentInfo ", currentInfo);
            return currentInfo;
          } 
     }
    }

  const reloadTs = useCallback(async()=>{
    const tsRes = await bookingContext.getValidTimeStampsWithTimes();
    if (tsRes !== undefined){
      if (tsRes.timeStamps){        
        setReloadTs(tsRes.timeStamps);
      }else{
        console.log("erreur : ", tsRes);
      }      
    }
  }, 
  []);
  const reloadTrainingTs = useCallback(async()=>{    
    const tsRes = await bookingContext.getAllValidTimeStamps();
    if (tsRes !== undefined){
      if (tsRes.timeStamps){
        console.log("reload training from context, ", tsRes.timeStamps);
        setReloadedTrainingTs(tsRes.timeStamps);
      }else{
        console.log("erreur : ", tsRes);
      }      
    }
  }, 
  []);

useEffect(() => {
  if (!props.validTs || props.validTs.length === 0 ){
    // reload from context
   /*  window.scrollTo({
      top: 50,
      left: 0,
      behavior: "smooth",
    }); */
    reloadTs();   
  }  
  if (!props.validTsStage || props.validTsStage.length === 0){
    /* window.scrollTo({
      top: 50,
      left: 0,
      behavior: "smooth",
    }); */
    reloadTrainingTs();
  }
  
  /** */
  set_isMounted(true);
  //onchange, init 
  setIsOvershot(false);
  //getStripeApiKeyByServer();
  async function getBookingsOnRadioChange(){
    // cours co
    if (selectedEvent === "4" || selectedEvent === "5"){
      console.log("cours co");
    try {
      const res = await bookingContext.getBookingsByCatAndUser(props.user);
      console.log("réponse : ", res);
      if (res.error){
        console.log("erreur récupération userbookings");
        setBookingLoaded(false);
      }else{
        const bookings = res.bookings;
        console.log("bookings by user : ", bookings);
        setBookingLoaded(true);
          const filteredBookings = bookings.filter((aBooking)=>{
            if (aBooking.category.toString() === selectedEvent){
              return aBooking;
            }
          });
          console.log("filteredBookings ", filteredBookings);
            // cours d'essais - set overshot to disable all dates if times over 2 res
          if (filteredBookings.length && filteredBookings.length >=2){
            console.log("on rentre dans la condition overshot");
            setIsOvershot(true);
          }else{
            setIsOvershot(false);
          }           
      }
    } catch (error) {
      console.log("récupération des bookingsbycat en cours");
    }
    }else if(selectedEvent === "1"){
    //cp
    setIsOvershot(false);
    console.log("cp : ", selectedEvent);  
    try {
      const res = await bookingContext.getBookingsByCat(selectedEvent);
      if (res.error){
        console.log("erreur récupération bookings");
        setBookingLoaded(false);
      }else{
        const bookings = res.bookings;
        console.log("bookings by cat : ", bookings);
        // bookings by cat only      
        setBookingsByCat(bookings); 
        setBookingLoaded(true);
      }
    } catch (error) {
      console.log("récupération des bookingsbycat en cours")
    }
  }
  }  
  // pour les cp / cours co, on vérifie si le créneau / les créneaux ne sont pas déjà réservés
  if (selectedEvent === "1" || selectedEvent === "4" || selectedEvent === "5"){
    console.log("condition tri : ", selectedEvent);
    getBookingsOnRadioChange();
  }
 
}, [_isMounted, reloadTs, reloadTrainingTs, selectedEvent, bookingContext.setReloadTs, bookingContext.reloadTsWithTimes, bookingContext.reloadTs]);

//COMPO UPDATE
useEffect(() => {  
 
  async function getBookingsOnRadioChange(){
    // cours co
    if (selectedEvent === "4" || selectedEvent === "5"){
      console.log("cours co");
    try {
      const res = await bookingContext.getBookingsByCatAndUser(props.user);
      if (res.data.error){
        console.log("erreur récupération userbookings");
        setBookingLoaded(false);
      }else{
        const bookings = res.bookings;
        console.log("bookings by causer : ", bookings);
        setBookingLoaded(true);
          const filteredBookings = bookings.map((aBooking)=>{
            if (aBooking.category.toString() === selectedEvent){
              return aBooking;
            }
          });
          console.log("filteredBookings ", filteredBookings);
            // cours d'essais - set overshot to disable all dates if times over 2 res
          if (filteredBookings.length && filteredBookings.length >=2){
            console.log("on rentre dans la condition overshot");
            setIsOvershot(true);
          }else{
            setIsOvershot(false);
          }           
      }
    } catch (error) {
      console.log("récupération des bookingsbycat en cours");
    }
    }else if(selectedEvent === "1"){
    //cp
    setIsOvershot(false);
    console.log("cp : ", selectedEvent);  
    try {
      const res = await bookingContext.getBookingsByCat(selectedEvent);
      if (res.error){
        console.log("erreur récupération bookings");
        setBookingLoaded(false);
      }else{
        const bookings = res.bookings;
        console.log("bookings by cat : ", bookings);
        // bookings by cat only      
        setBookingsByCat(bookings); 
        setBookingLoaded(true);
      }
    } catch (error) {
      console.log("récupération des bookingsbycat en cours")
    }
  }
  } 
  if (bookingLoaded === false && props.user){
    getBookingsOnRadioChange();
  }

})


  const getTimeStampsByCat = async (categoryId, timeStamps) =>{ 
  
    console.log("on rentre dans getTsByCat, la ctégorie : " + categoryId)       
    // si on a des créneaux, on ne retourne que ceux de la categorie en param
    if (timeStamps !== undefined && timeStamps !== null){        
      const finalTimeStamps = timeStamps.filter(stageTs=>
      stageTs.category.toString() === categoryId.toString());           
      return {tsByCat: finalTimeStamps};
    }else{
      return {message: "... Chargement en cours"};
    }
}


  const handleRadioChange =  async (event) =>{
    setIsOvershot(false);
    setSelectedEvent(event.target.id);
    console.log("change event : ", event.target.id);    
    userContext.setIsFullWeek(false);
    userContext.setSelectedDate(null);
    
    // school
    if (event.target.id !== "6"){
      setSchoolIsSelected(false);
      userContext.setIsDateClicked(false);
      // on a sélectionné un evenement
      setEventSelection(true);
      // reset choiceIsOk
      userContext.setChoiceIsOk(false);
      //reset input select on chbox change
      userContext.setSelectedValue('');
      //reset timestamp on chbox change
      userContext.setTsIsOk(false);
      console.log("val de select input : " + defaultSelection);
      /*reset message storage on chbox change*/    
      setMyError("");   
      setSelectedOption(event.target.id);
      userContext.setSelectedDate(null);
      if (event.target.id !== "2"){
        // message cours
          if (props.message){
            setMyError(props.message);
          }
      
      const resp = await getTimeStampsByCat(event.target.id, reloadedTs);
      if (resp !== undefined){
        
        if (resp.tsByCat){       
          setItems (resp.tsByCat);                  
          console.log(resp.tsByCat);             
          setDaysToDisable(bookingContext.setListToDisable(resp.tsByCat, event.target.id));
   
        }
      }else{
        setMyError("... Chargement en cours");
      }
    }else{ //stage
      const resp = await getTimeStampsByCat(event.target.id, reloadedTrainingTs);
        // stage
        if (props.messageStage){
          setMyError(props.messageStage);
        }    
      if (resp !== undefined){
        setItems (resp.tsByCat);                  
        console.log(resp.tsByCat);             
        
      }
    }
  }else{    
      setSchoolIsSelected(true); 
      disableBookingPossibility();
  }
}
const showInfo = (e)=>{
  e.preventDefault();
  remindMe ? setRemindMe(false):setRemindMe(true);
}
function disableBookingPossibility(){
  
  userContext.setIsDateClicked(false);
  setEventSelection(false);
  // reset choiceIsOk
  userContext.setChoiceIsOk(false);
  //reset input select on chbox change
  userContext.setSelectedValue('');
  //reset timestamp on chbox change
  userContext.setTsIsOk(false);
  /*reset message storage on chbox change*/ 
  setMyError("");
}

  const EVENTS = [
  {id: 1, description: "Cours personnalisé / balade", position: 1}, 
  {id: 2, description: "Stage", position: 2}, 
  {id: 5, description: "Cours d'essai senior", position: 3}, 
  {id: 3, description: "Goûter d'anniversaire", position: 4}, 
  {id: 4, description: "Cours d'essai junior", position: 5}, 
  {id: 6, description: "Activité périscolaire", position: 6}];

  const eventList =   
      <div className="radio" onChange = {handleRadioChange}> 
          {EVENTS.map((item)=>
          // radio buttons events
              <div className= "radio-sub" key={item.position}>               
                  <input
                    type="radio"
                    id={item.id}
                    className="radio-input"
                    name="rbEvents"
                    value={item.description}
                    defaultChecked={false} />
                  <span className="checkmark"></span>
                  <label className="radio-label">            

                {item.description}  </label>

              </div>
               
            
          )
          }
      </div>

console.log("tstodisable follow...", props.tsToDisable);

  return (
    <>
    <div className="pricing">
    <h2 className="form-h1">Nos tarifs </h2>
    <span className="pricing-title"> Cours personnalisé (1 heure) :  </span>                   
    <span className="pricing-span"> 45 € par personne </span>
    <div className='pricing-p'>
    <span className="pricing-title"> Cours d'essai junior (1 heure en cours collectif, 2 cours d'essai max) :  </span>                   
    <span className="pricing-span"> 19 € par personne </span>
    </div>
    <div className='pricing-p'>
    <span className="pricing-title"> Cours d'essai senior (1 heure en cours collectif, 2 cours d'essai max) :  </span>                   
    <span className="pricing-span"> 39 € par personne </span>
    </div>
    <div className="pricing-p">
    <span className="pricing-title"> Goûter d'anniversaire (3 heures) :  </span>
    
    <div>
    <span className="pricing-span"> 7 enfants minimum : 19 € par enfant  </span>
    </div>
    
    </div>
    <div className="pricing-p">
    <span className="pricing-title"> Stage 5 jours (pendant les vacances scolaires) : </span>
    <div>
    <span className="pricing-span"> 60 € par personne et par jour ou 250 € le stage complet</span>
    <h3 className="form-h1">Important : 
    <span> Réglement intégral de la prestation pour les activités Stage | Cours d'essai | Cours personnalisé</span> 
    
    </h3>
    </div>
    </div> 
    <div className="pricing-p">
    <span className="pricing-title"> Activité périscolaire : </span>
    <div>
    <span className="pricing-span"> Nous contacter pour un devis personnalisé</span>
    </div>
    </div>    
    </div>
  
    
    {/* radioButtons */}
    
    <StyledEvent>
       
    <div className="event-div">
      <label className="form-label">1 - Votre prestation</label>
    </div>
      {eventList}
    <div className="school">{schoolIsSelected ? "Merci de nous contacter pour convenir des horaires et obtenir un devis personnalisé" : isOvershot ? "Vous avez dépassé votre quota de cours d'essais,  merci de sélectionner une autre option" : ""} </div>
    <div className='info-user'>
    {selectedEvent && selectedEvent !== "" && !schoolIsSelected && (selectedEvent === "1" ||selectedEvent === "4" ||selectedEvent === "5") && <div className='info-sentance'>
        {bookingSentance}
        <button className='remind-button' onClick={(e)=>{showInfo(e)}}>
          <div>Rappel sur les horaires </div>
          <div className={remindMe ? 'close-v':'open-v'}>V</div>
        </button>
      </div>
    }
    {remindMe && selectedEvent && selectInfoByCat(parseInt(selectedEvent, 10)).map((anEvent)=>(
      <div key={anEvent.categoryId} className='info-events'>        
        <div className='info-levels'>
          {anEvent.levels.map((aLevel, index)=>(
            <div className="info-lesson-wrapper" key={index}>
              {aLevel.lesson && aLevel.lesson !== "" &&<p className='info-lesson'>{aLevel.lesson}</p>}
              <div className='info-lesson-days-wrapper'>
                <p className='info-day'>{aLevel.day && aLevel.day}</p>
                  {aLevel.hour && 
                    <div className='info-hours'> {aLevel.hour.map((anHour, key)=>(
                    <label key={key}>{anHour}</label>
                    ))}              
                    </div>
                  }
              </div>
            </div>        
        ))}
        </div>
      </div>
    ))}
    </div>
    </StyledEvent>
    
    <div><Label isvalid={false}>{myError && myError}</Label></div>
    <Stripe isAuth={props.isAuth} user={props.user} isOvershot={isOvershot} schoolIsSelected={schoolIsSelected} tsToDisable={props.tsToDisable} step = {selectedOption === "3" ? props.step : props.step+1} start = {props.start} bookingsbycat={bookingsByCat} eventisok = {eventSelection} presta = {selectedOption} items={items} daysToDisable={selectedOption !== "2" ? daysToDisable : items}/>
    
    </>
    )
  }
  export default NoBabyContent
          


import React from 'react';
import '../App.css';


const SelectInputAdmin = React.forwardRef((props, ref)=>(
    
    <select ref={ref} className={props.className}  onChange={props.onChange}>
        <option></option> {/* Nothing preselected */}
                 {props.table.map((category)=>(
                        <option value={category.id} key={category.id}>
                            {category.label}
                        </option>
                ))}
    </select>
    ));
export {SelectInputAdmin}
import React from 'react';
import logoadmin from "../images/logoEcurieUlysse.png";
import logo from "../images/logoEcurieUlysse_res.png";



export default function Logo(props) {
    return ( 
        
        <img src={props.user && props.user.status === 99 ? logoadmin :  logoadmin } className={props.user && props.user.status === 99 ? "logo":"logo-admin"} alt="logo Ulysse"/>
       
      
    )
}

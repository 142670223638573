import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { LabelValidEmail } from '../common';


export const AuthMessage = ({onClick, isauth, email, ismailsent})=>(
    <div className="div-center">
        
        {(isauth && isauth === 1 && ismailsent === true) && 
        <LabelValidEmail isvalid={false}>
        Un mail vous a été envoyé. Merci de vérifier votre boîte mail et vos spams                
        </LabelValidEmail>
        }
        {(isauth && isauth === 1 && ismailsent === false) 
            && 
        <LabelValidEmail isvalid={false}>
        Merci de prendre un moment pour valider votre adresse mail. Vous recevrez ainsi la confirmation de vos rendez-vous. 
        En attendant, vous pouvez visualiser ces derniers dans la LISTE DE VOS PROCHAINES RÉSERVATIONS.                
        </LabelValidEmail>
        }
        {(isauth && isauth === 1 && ismailsent === false) 
            && <span>Email perdu dans vos spams ? </span>}

        {(isauth && isauth === 1 && ismailsent === false) 
            &&              
           <Link to="#" className="link-forgetpwd" onClick={()=> onClick(email)}>Renvoyer le mail</Link>}
    </div>
            
)

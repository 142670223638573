import React, { Component } from 'react';
import '../App.css';
import SignInForm from '../components/SignInForm';
import SignUpForm from '../components/SignUpForm';
import {Wrapper, BckgdBottom} from "../common/index";
import bottomImg from "../images/rudyManege.png";
import {withUser} from "../contexts/UserContext";
import '../data/connectionStrings';



/* const ELEMENTS_OPTIONS = {
    fonts : [
        {cssSrc : 'https://fonts.googleapis.com/css?family=Roboto',
    },
    ],
}; */
const ClearColor ="grey" ;
const MiddleColor = "#77B82A";


class Sign extends Component {
    
    constructor(props){
        super(props);
        this._isMounted = false;
    }
    state={
        isTimeOut: false
    }
    logout = async () => {
        const user = this._isMounted && await this.props.context.logout();
        this.setState({user})
    }
    
    
    
    componentDidMount(){
        this._isMounted = true;
        const userContext = this.props.context && this.props.context; 
        userContext.setLogoutClick(false);
    }
    componentWillUnmount(){
        this._isMounted = false;
        
    }

    render() {
       
    
        return (
            <>      
             
            <div className="pricing"><h1 className="form-title">Réservation</h1></div>
           
            <Wrapper>
            {/* options={ELEMENTS_OPTIONS}  désactivé pour test*/}       
                <SignInForm clearcolor={ClearColor} middlecolor={MiddleColor} /> 
            <h1 className="or"> Ou </h1>      
                <SignUpForm clearcolor={ClearColor} middlecolor={MiddleColor} comefrom="home" title = "Créez votre espace et réservez votre prestation" istohide={false} btnlabel="Inscription"/>
              
            </Wrapper>           
                <BckgdBottom src={bottomImg}/> 
            </>
        )
        
    }
}
export default withUser(Sign)

const createPaymentIntent = options => {  
 console.log("on rentre dans payment intent de stripeapi")
    return window
      .fetch(`/api/v1/create-payment-intent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(options)
      })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      .then(data => {
        if (!data){
          console.log("Pas de retour");
        }else if (data.error) {
          console.log("API error:", { data });
          return data.error;          
        } else {          
          return data.paymentIntent.client_secret;
        }
      });
  };
  
  const getBookingDetails = (category, quantity, isFullWeek, nbDays) => {
    console.log("cat : " + category + " nbDay : " + nbDays + " quant : " + quantity + "isfull : " + isFullWeek);
    return window
      .fetch(`/api/v1/bookingdetails?category=${category}&quantity=${quantity}&isfullweek=${isFullWeek}&nbdays=${nbDays}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      .then(data => {
        if (!data || data.error) {
          console.log("API error:", { data });
          throw Error("API Error");
        } else {
          return data;
        }
      });
  };
  
  const getPublicStripeKey = async (options) => {
    return window
      .fetch(`/api/v1/public-key`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      .then(data => {
        if (!data || data.error) {
          console.log("API error:", { data });
          throw Error("API Error");
        } else {
          return data.publicKey;
        }
      });
  };


  const  getServerStatus = async() =>{
    
    return window
      .fetch(`/api/v1/getserverstatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          return null;
        }
      })
      
      .then(data => {
        if (!data){
          console.log("Pas de retour serveur status");
        } else if (data.error) {          
          return data.error;          
        } else {            
          return data.serverStatus;
        }
      });
   
}

  
  const stripeApi = {
    createPaymentIntent,
    getPublicStripeKey: getPublicStripeKey,
    getBookingDetails: getBookingDetails,
    getServerStatus: getServerStatus
  };
  
  export default stripeApi;
  
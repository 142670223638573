import React, { Component, Suspense } from 'react'
import { Link } from 'react-router-dom';
import { Label } from '../common';




export const AuthMessageForSignUp = ({ isdone, issubmitted, message})=>(
   <>
            <Suspense fallback={<Label isvalid={true}>
             {isdone === false && "Chargement de profil en cours..."}</Label>}>
                {/* si le message contient plusieurs messages, on en fait un tableau pour éviter la concurrence de messages */}
            <Label isvalid={false}>
                {(isdone === true && issubmitted === true && message !== undefined && message !== null && message !== "") 
                && message}
                
                </Label>           
             </Suspense>
            </>
               
)

import React, { Component } from 'react';
import AdminDayCard from './AdminDayCard';
import '../App.css';
import {StyledFormCardsDaysAdmin, Label} from '../common';
import addMilliseconds from 'date-fns/addMilliseconds';
import axios from 'axios';
import connectionStrings from '../data/connectionStrings';
import {sortTimeStampsByCat} from '../data/clientUtils';

const SERVER = connectionStrings.SERVER;

export default class Days extends Component {

constructor(props){
    super(props);
    this._isMounted = false;
 
    

    this.state = {   
        validityDate:""  ,
        message:"",
        finalTimeStamps:[],
        validTimestamps: [],
        hours: [],
        tempTimes: [],
        recievedTimeStamps:[] 
    }
}

async componentDidMount(){
    this._isMounted = true;
    if (!window.navigator.onLine){
        this.setState({message: "Oups, il semblerait que vous ayez perdu votre connexion internet"});
    }else{
        const resp = this._isMounted && await this.getTimeStampsWithTimes();
        if (resp !== undefined){
            if (resp.message){
                this.setState({message: "Aucun créneau disponible"});
            }else{ // récupération des heures
                if(resp.timestamps){
                console.log("timestamps : ",resp.timestamps) ;
                this.setState({validTimestamps: resp.timestamps}); 
                // on trie par catégorie            
                const finalTimeStamps = sortTimeStampsByCat(this.props.category, resp.timestamps);
                console.log("timestamps triés : ", finalTimeStamps) ;
                this.setState({finalTimeStamps: finalTimeStamps});          
            }
        
        }
        }else{
            this.setState({message : "...Chargement en cours"});
        } 
    }
}

/**
 * CONTEXT
 * @param {*} timeStamps 
 */
setValidTimes(timeStamps){ 
    console.log("on rentre dans setvalditimes")       
    if(timeStamps !== undefined){
        console.log("dans setValidTimes : not undefined")
        let newTimeStamp;
        let filteredTimeStamps=[];
        timeStamps.forEach(timeStamp => {
            
            newTimeStamp = {
                category : timeStamp.category,
                created_at : timeStamp.created_at,
                updated_at : timeStamp.updated_at,
                id : timeStamp.id,
                num_day: timeStamp.num_day,
                times :                
                timeStamp.times.filter(time=>
                (time.dt_expirity === "infinity"? Infinity : time.dt_expirity) > Date.now() && new Date (time.dt_effect) <= Date.now())
                
            }
            filteredTimeStamps.push(newTimeStamp);
          
        });            
      
    console.log("les heures filtrées : " , filteredTimeStamps);
    return filteredTimeStamps;
    
}
}

/**
 * @returns 
 */
getTimeStampsWithTimes = async ()=>{
    const CancelToken = axios.CancelToken;
    let cancel;
    try {
          const resp = await axios.get(`${SERVER}/admintimes`, {
            headers:{'timesheader': 'times'}
            },            
            {cancelToken: new CancelToken(function executor(cancelParam) {
                // An executor function receives a cancel function as a parameter
                cancel = cancelParam;
          })});
          if (resp !== undefined){              
                if(resp.data.error){
                      console.log("Erreur pendant le chargement des heures : " + resp.data.error);
                      cancel();
                      return {message: "Une erreur est survenue pendant le chargement des heures"};
                }else{
                      console.log("les heures récupérées : " + resp.data.timestamps);
                      cancel();
                      return {timestamps : resp.data.timestamps};
                }
          }else{
                console.log("... chargement en cours");
                return {message : "... chargement en cours"};
          }
    } catch (error) {
          console.log("erreur dans la récupération des heures : " + error);
          cancel()
          return {message : "Erreur dans la récupération des heures : " + error};
    }
}

handleValidityDate=()=>{
    // 3 mois après la date courante
    const milliDatePast3 = addMilliseconds(new Date(), 7776000000); 
    return milliDatePast3.toLocaleDateString();
}

componentWillUnmount(){
    this._isMounted = false;
}

filterTimeStampsByNumDay(numDay){ // la requete renvoie déjà un order by dt_expirity desc, vérifier si ok
    console.log("on rentre dans timestampbynumday");
    const filteredTs = this.state.finalTimeStamps && this.state.finalTimeStamps.filter(ts=>ts.num_day === numDay);
    console.log("les timestamp par numday " , filteredTs);   
    return filteredTs;
}




    render(){
       const finalTimeStamps = this.state.finalTimeStamps !== undefined ? this.state.finalTimeStamps : "... chargement en cours"
       const message = this.state.message && this.state.message
       const category = this.props.category && this.props.category
       console.log("admin baby finalTimeStamps :", finalTimeStamps);
       console.log("admin baby cat :", category);
    return(
        <> 
        <Label isValid={false}>{message}</Label>
        <StyledFormCardsDaysAdmin>       
        {finalTimeStamps && finalTimeStamps.length > 0 ? finalTimeStamps.map((ts, index)=>      
            <AdminDayCard 
            key={index}
            num_day = {ts.num_day}
            hours= {ts.times}
            category={category}
            selectedHour={this.state.selectedHour}
            selectedMinute={this.state.selectedMinute}
            onChange={(e)=>this.handleSelectChange}
            /> 
              
            )// fin map
            :"... Chargement en cours"
        }
        </StyledFormCardsDaysAdmin>
        </>
        )
            
    }  
}

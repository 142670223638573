import React, { Component, Suspense } from 'react';
import Sign from './Sign';
import {withUser} from '../contexts/UserContext';
import RendezVous from '../components/RendezVous';
import stripeApi from "../stripeApi";


class Home extends Component {
    constructor(props){
        super(props);
        // disable reactdevtools for production
    stripeApi.getServerStatus().then((status)=>{
        
        if (status === 1)  {      
            this.betaMessage = "La plateforme est en cours de maintenance, les réservations sont momentanément indisponibles.";
        }else if (status === 3){
            this.betaMessage = "La plateforme est en cours de maintenance, les réservations sont momentanément indisponibles.";
        }else{
            this.betaMessage = "";
        }
       });

        
    }
    
   
    render() {
        return (
            <>
            {/* Pour centrer "nos rendez-vous" */}           
            
            <div className="header">
                <div className="left-container"></div>
                <div className="center-container-rdv">
                    <RendezVous pathcolor = "yellowgreen"/>
                </div>
            <div className="right-container"></div>
            </div>
            
           
            <div className="page">
            <div className={this.betaMessage && this.betaMessage !== "" && "border-beta"}>{this.betaMessage !== "" && this.betaMessage}</div>
            <h1 className="page-title">Bienvenue sur la plateforme de réservation des Écuries d'Ulysse</h1>
                <p>                
                Ce lieu vous permet de prendre rendez-vous pour les activités occasionnelles proposées par les Écuries            
                </p>
                <div className="paragraph">                
                <h2 className="page-title">Prestations : Baby poney (balade en mains), cours personnalisé, balade, stage, anniversaire, activité périscolaire</h2>         
                </div>
            
            </div>
                    
            <Sign/>
        
            </>
        )
    }
}
export default withUser(Home)

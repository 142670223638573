import React, {Suspense, useContext, useEffect, useState} from 'react';
import './App.css';
import Header from './components/Header';
import Home from './pages/Home';
import {Route, Routes} from 'react-router-dom';
import ReInitPwd from './pages/ReInitPwd';
import Booking from './pages/Booking';
import Confirm from './pages/Confirm';
import {UserContext} from "./contexts/UserContext";
import Sign from './pages/Sign';
import Admin from './pages/Admin';
import Captcha from './components/Captcha';
import stripeApi from "./stripeApi";
import SignUpForm from './components/SignUpForm';
import SignInForm from './components/SignInForm';
import axios from 'axios';






export default function App ({...props}) {
 
  const theContext = useContext(UserContext);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [count, setCount] = useState(0);
  const isAuthUser =  theContext.isAuth;
 

// MOUNT
  useEffect(()=>{
    // test axios
    axios.get(`http://127.0.0.1:5000/api/v1/public-key`)
  .then(response => {
   console.log("server ok");
  })
 
 

function disableReactDevTools(){
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === "object") {
    for (let [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == "function" ? ()=>{} : null;
    }
  }
}
function disableConsoleLog(){
  console.log = function () {};
}
     // disable reactdevtools for production
    stripeApi.getServerStatus().then((status)=>{
      if ( status === 2 || status === 1 || status === undefined){
        disableReactDevTools();
        disableConsoleLog();
      }
     
     });
    
  
   
  /* Ici gestion de l'affichage  : confirmation de création de compte et confirmation
  de validation email */
    if (count < 1 && theContext.dbUser.is_confirmed === true && isConfirmed === false) {
      setIsConfirmed(theContext.dbUser.is_confirmed); // user mis à jour après clic sur le lien
      setCount(count => count+1);
    }

  }, []);

// UPDATE
useEffect(()=>{
 
});
  

    
    return (

      <div id="appid" className="App">    
    
      <Header />  
        
      <Suspense fallback={<div>Chargement en cours ...</div>}>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route 
            path="/connexion"
            element={
              <Sign/>
            }/>
            <Route 
            path="/signup"
            element={
              <SignUpForm
               title = "Créez votre espace et réservez"
               btnlabel = "Inscription"
               comefrom = "home"
               />
            }/>
            <Route 
            path="/login"
            element={
              <SignInForm/>
            }/>
            
            <Route 
            path="/admin"
            element={
              <Admin/>
            }/>
                      
          <Route path="/booking" element={<Booking/>}/>        
          
          <Route 
          path="/pwdreinit" element={<ReInitPwd/>}/>
         <Route 
          path='/logout' 
          element={
            <Confirm
            isTimeOutAuto = {true}
            titleconfirm=" Vous êtes déconnecté"
            bodyconfirm= " Les réservations ne sont accessibles qu'aux utilisateurs enregistrés"
            infoconfirm= "Vous devrez vous connecter pour consulter votre tableau de bord"
            labelconfirm="Retour à la page de connexion"   
            hourchoice = ""
            datechoice = ""
            isreinit={false}
            isbookingconfirm={false}
            ismailnotfound={false}
            /> 
         }></Route>

<Route 
          path='/adminlogout' 
          element={
            <Confirm
            isTimeOutAuto = {true}
            titleconfirm=" Vous êtes déconnecté"
            bodyconfirm= ""
            infoconfirm= "Vous devrez vous connecter pour consulter votre tableau de bord"
            labelconfirm="Retour à la page de connexion"   
            hourchoice = ""
            datechoice = ""
            isreinit={false}
            isbookingconfirm={false}
            ismailnotfound={false}
            /> 
         }></Route>
          <Route 
          path="/reinitconfirm"          
          element={
            <Confirm 
            isTimeOutAuto = {true}
            titleconfirm="Votre mot de passe a bien été réinitialisé"
            bodyconfirm="Vous pouvez désormais vous connecter"
            labelconfirm="Retour à la page de connexion"
            isreinit={true}
            isbookingconfirm={false}
            ismailnotfound={false}
            
            />
          }
          />
          <Route 
          path="/accountconfirm" 
          element={
            <Confirm
            isTimeOutAuto = {true}
            is_confirmed = {isConfirmed}
            titleconfirm="Votre compte a bien été créé."
            bodyconfirm= " Un mail vous a été envoyé, merci de cliquer sur le lien pour confirmer vos coordonnées. En cas de non réception, merci de vérifier vos courriers indésirables. Si vous ne recevez pas de mail, merci de nous contacter à l'adresse suivante : "
            labelconfirm="Retour à la page de connexion"
            isreinit={false}
            isbookingconfirm={false}
            ismailnotfound={true}   
            />
          } 
          />
       
          <Route 
          path="/bookingconfirm" 
          element={
            <Confirm
            isTimeOutAuto={false}            
            infoconfirm= "Vous pouvez la visualiser dans la LISTE DE VOS RÉSERVATIONS. Un mail de confirmation vous a été envoyé"
            labelconfirm="Retour à votre espace" 
            isreinit={false}
            isbookingconfirm={true}           
            ismailnotfound={false}   
            
            />
          }
          />
  
          <Route 
          path="/mailisok"         
          element={
            <Confirm
            isTimeOutAuto={true}
            is_confirmed = {isConfirmed}
            titleconfirm= "Votre adresse email a bien été validée"
            bodyconfirm= "Vous pouvez maintenant profiter pleinement du suivi par mail"
            labelconfirm="Retour à la page de connexion"
            isreinit={false}
            isbookingconfirm={false}
            ismailnotfound={false}   
            />
          }            
          />
          <Route 
          path="/mailiseverok"         
          element={
            <Confirm
            isTimeOutAuto={true}
            is_confirmed = {isConfirmed}
            titleconfirm= "Vous avez déjà validé votre adresse mail"
            bodyconfirm= "Vous pouvez vous connecter"
            labelconfirm="Retour à la page de connexion"
            isreinit={false}
            isbookingconfirm={false}
            ismailnotfound={false}
            />
          }            
          />
          <Route 
          path="/mailiserror"         
          element={
            <Confirm
            isTimeOutAuto={true}
            is_confirmed = {isConfirmed}
            titleconfirm= "Un problème est survenu lors de la confirmation de votre adresse mail, merci de nous contacter en suivant ce lien :"
            bodyconfirm= "Réessayez ultérieurement"
            labelconfirm="Retour à la page de connexion"
            isreinit={false}
            isbookingconfirm={false}
            ismailnotfound={true}
            />
          }            
          />
          <Route 
          path="/mailnotfound"         
          element={
            <Confirm
            isTimeOutAuto={true}
            is_confirmed = {isConfirmed}
            titleconfirm= "On ne vous trouve pas" 
            bodyconfirm= "Si vous avez des difficultés à créer votre compte, merci de contacter l'administrateur en suivant ce lien : "
            labelconfirm="Retour à la page de connexion"
            ismailnotfound={true}
            isreinit={false}
            isbookingconfirm={false}
            />
          }            
          />
          

          <Route 
          path="/*" 
          element={
            <Confirm
            isTimeOutAuto={true}
            titleconfirm="Erreur 404"
            bodyconfirm= "La page que vous demandez n'est pas disponible"
            infoconfirm= "Merci de vous connecter pour accéder à la réservation"
            labelconfirm="Retour à la page de connexion"   
            hourchoice = ""
            datechoice = ""
            isreinit={false}
            isbookingconfirm={false}  
            ismailnotfound={false}          
            />
            }/>
      </Routes>
    </Suspense>
    <Captcha/>
    </div>
   
    )
}



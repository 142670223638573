import React from 'react';
import styled from 'styled-components';


export default function RendezVous(props) {
	const pathColor = props.pathcolor;
return(
	
	<svg version="1.1" className="rendezvous" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="500px" height="200px" viewBox="0 25 500 251" enableBackground="new 0 0 500 251" xmlSpace="preserve">
	<path id="nos" fill="none" stroke={pathColor} strokeMiterlimit="10" d="M65,135.25c0,0,11.5-8.25,13.5-8.5s4,1.25,3.75,4.25
	S70.244,159.021,70,160.5c-0.313,1.893,1.911,2.191,3.625,0.063C77.75,155.438,89.243,128.731,97,128.25
	c-4.188,6.875-15.525,36.683-8.25,34c8.813-3.25,11.625-10.875,11.625-10.875S99.5,159.25,106,158s8.25-15.5,4.5-19.5
	s-11,11-9.25,14s12.25,0.25,17-3s8.917-9,11.063-15.188c0.197-0.569,3.451,5.24,4.521,8.688c3,9.667-3.427,13.781-8.677,12.531
	s-3.406-6.781-3.406-6.781"/>
	<path id="ren" fill="none" stroke={pathColor} strokeMiterlimit="10" d="M154.741,142.462c2.634-2.587,3.759-4.712,3.509-8.212
	S155,131,154.75,133s4.5,2.5,8.25,2s4.75-0.5,4.25,1.75s-6.595,13.611-2.75,12.375c1.75-0.563,5.275-4.119,8.921-6.389
	c6.204-3.861,14.829-7.861,13.454-11.736c-1.482-4.179-9.188-0.813-11.625,5.5c-1.776,4.6-0.656,16.029,5.25,11.313
	c4.761-3.802,6.563-6.188,9.563-9.955c3.503-4.399,7.063-8.732,7.063-8.732s-4.457,10.387-3.875,14.125
	c0.438,2.813,3.787-3.131,5.583-6.083c4.667-7.667,6.932-11.046,7.917-9.167c0.688,1.313-8.611,14.402-1.086,15.978
	c2.836,0.594,5.669-1.516,8.126-3.949"/>
	<path id="d" fill="none" stroke={pathColor} strokeMiterlimit="10" d="M226.694,131.3c0.051-5.334-8.006-10.862-13.194-1.966
	c-3.474,5.958,0.689,20.899,12.714,8.083"/>
	<path id="ez" fill="none" stroke={pathColor} strokeMiterlimit="10" d="M233.25,105.786c-3,7.964-9.875,34.214-5.755,33.76l0,0
	c1.75-0.563,6.107-4.535,9.753-6.805c6.204-3.861,14.83-7.861,13.455-11.736c-1.482-4.179-9.188-0.813-11.625,5.5
	c-1.776,4.6-1.078,15.446,5.25,11.313c9.146-5.974,11.256-11.233,13.006-16.984c1.75-5.75,3.298-0.805,9.563-3.938
	c1.156,1.369,0.204,3.928-1.5,5.938c-1.894,2.232-7.532,5.299-5.855,6.417c2.25,1.5,9.25,9.75,1.75,17.375s-10,5.375-7.75,0
	s12.5-15.625,17.25-20.375"/>
	<line id="tiret" fill="none" stroke={pathColor} strokeMiterlimit="10" x1="278.071" y1="128.439" x2="291.333" y2="126.97"/>
	<path id="vous" fill="none" stroke={pathColor} strokeMiterlimit="10" d="M300.125,116.563c-2.25,5.5-0.452,18.911,7.375,15.625
	c4.015-1.686,6.5-7.188,8-14.688s-7.117-7.433-8-5.813c-1.625,2.983,4.8,3.936,7.25,3.75s9.188-1.938,7.875,0.438
	c-1.989,3.599-1.5,9.625,1.125,12.125c2.318,2.208,7.25-0.25,8.25-7s-1.463-8.015-3.563-8.75c-3.75-1.313-8.916,2.405-3.75,4.625
	c5.125,2.202,10.313-0.563,14.563-3.813s6.75-4.674,6.75-4.674S336,124,342,125.5s11.5-11.474,14.25-17.112
	s-8.897,19.399-1.75,17.112c6.25-2,13.892-10.868,16.875-17.875c2.688-6.313,1.194-7.466,0.938-7.75
	c-2.136-2.359-4.664,0.2-3.188,2.5s8.532,4.988,8.688,9.75c0.25,7.688-5.313,10.625-9.063,10.375s-5.239-7.891,0.512-5.334
	s6.28,5.918,11.801,4.334s12.938-8.563,13.875-9.625"/>
	<path id="underline" fill="none" stroke={pathColor} strokeMiterlimit="10" d="M93.582,210.305
	c97.893-41.323,181-60.929,290.598-71.95"/>
</svg>

)
}


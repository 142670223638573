import React, {Component} from 'react';
import {
  StyledFold_content, 
  StyledFold_trigger, 
  StyledFold, 
  StyledAccordion,   
  PanelTitleDisplay} from "../common";
import {withUser} from "../contexts/UserContext";


class Accordion extends Component {
     

    
    constructor(props) {
      super(props);
      this._isMounted = false;
     
      this.state = {
        active: -1,
        user: this.props.user,
        data : this.props.presta.map((item, index)=>(
           this.format(
             index,
             item.title, 
             item.subtitle, 
             item.description, 
             item.content,
             item.presta, // type de prestation      
             item.svg,
             item.type // type d'accordéon
            )
        ))
      };
      
    }
   
     componentDidMount(){
      this._isMounted = true;     
      
    }
    componentWillUnmount(){
      this._isMounted = false;
    }

    format = (index, title, subtitle, description, content, presta, svg, type) => {      
        const inner =
          <div key={index}>
            <div className="paragraph">{subtitle} </div>
            <div className="paragraph">{description} </div>
            <div className="paragraph">{content}</div>  
           
          </div>;
        console.log("le contenu : " + inner.subtitle)
        console.log("le type : " + type);
        return {title, svg, inner, presta, type}; 
      };
    /***
     * Selects the given fold, and deselects if it has been clicked again by setting "active to -1" 
     * */
    selectFold = async (foldNum, type) => {
      console.log("on rentre dans selectFold");
      console.log("dans selectFold, type : " + type);
      const current = this.state.active === foldNum ? -1 : foldNum;
      this.setState(() => ({ active: current }));
       // reload the user from context : for updated bookings
       console.log("type d'accordéon: " + type);
       if(type==="-1"){
        console.log("condition is to reload");
        this.props.context.setUserIsToReload(true);
       }
       /* if(type === "-1"){
        console.log("on lance reloaduserandbookings");
        
        const updatedResult = this._isMounted && await this.props.context.reloadUserAndBookings(this.props.context.user.email);
        if(updatedResult !== undefined){
          if(updatedResult.user){
            console.log("reloaded user from accordion : ", updatedResult.user);
            this.setState({user:updatedResult.user});
          }else{
            console.log("erreur reload user : ");
          }
          
        }
      }else{
        this.props.context.setSelectedValue("");
      } */

     
    };
  
    render() {
      return (
        <StyledAccordion>
          {this.state.data.map((content, i) => {
            return (
              <Fold
                key={`${i}-${content.title}`}
                content={content}
                handle={() => this.selectFold(i, content.type)}
                active={i === this.state.active}
                presta={content.presta}
                type={content.type}
              />
            );
          })}
        </StyledAccordion>
      );
    }
  }
  
  class Fold extends React.Component {
    render() {
      return (
        <StyledFold>         
          <StyledFold_trigger
            className={`${this.props.active ? "open" : ""}`}
            onClick={this.props.handle}>   
            <PanelTitleDisplay> 
            <div className="panel-left-container">{this.props.content.svg}</div>
            <div className = {`${this.props.presta === "-1" ? "panel-title-abs" : "panel-title"}`}>{this.props.content.title}</div>
            <div className={`${this.props.presta === "-1" ? "panel-plus-abs" : "panel-plus"}`}>{this.props.active ? "-" : "+"}</div>
            </PanelTitleDisplay>
          </StyledFold_trigger>  
          <StyledFold_content             
              presta = {this.props.presta} 
              key="content"
              className={`${this.props.active ? "open" : ""}`}>
              {this.props.active ? this.props.content.inner : null}
            </StyledFold_content>
        </StyledFold>
      );
    }
  }
  export default withUser(Accordion)
  
 
 
  
 
  
